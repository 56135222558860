import { CwsIcon } from '@components/cws';
import React from 'react';

export const LOGO_TALLINK = 'logo-tallink-e-store-long';
export const LOGO_TALLINK_SILJA = 'logo-tallink-silja-short';
export const SILJA_SIZE_WIDTH = 70;
export const SILJA_SIZE_HEIGHT = 24;
export const TALLINK_SIZE_WIDTH = 100;
export const TALLINK_SIZE_HEIGHT = 45;

interface IconProps {
  isPriceListApp: boolean;
}

export const IconTallink: React.FC<IconProps> = ({ isPriceListApp }) => {
  return (
    <CwsIcon
      width={isPriceListApp ? SILJA_SIZE_WIDTH : TALLINK_SIZE_WIDTH}
      height={isPriceListApp ? SILJA_SIZE_HEIGHT : TALLINK_SIZE_HEIGHT}
      icon={isPriceListApp ? LOGO_TALLINK_SILJA : LOGO_TALLINK}
    />
  );
};
