import type { PriceInfo } from '@model/product/PriceInfo'
import { useRouter } from 'next/dist/client/router'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import { getPrices } from '@utils/product/product-info'

import { useEffect, useMemo, useState } from 'react'
import { getCurrencyOptByLanguageId } from '@hooks/useCurrency'
import type { Product } from '@model/product'
import type { Promotion } from '@model/misc/Promotion'
import { KK_STORE } from '@api/apiConfig'
import type { KK_STORE_TYPE } from '@pages/api/proxy'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import { isPromotionValidForCatalog } from '@utils/promotion'
import type { Catalog } from '@model/pricelist/Catalog'
import { query } from 'winston'
import { useInitialData } from '@context/initialDataContext'

export const usePriceInfo = (product: Product, promotions: Promotion[] = []): PriceInfo => {
  const {
    locale,
    query: { catalogId },
  } = useRouter()
  const {
    initialData: { currencies },
  } = useInitialData()

  const storeId = KK_STORE || isGloballyPricelist() ? 'store1' : 'store2'
  // const [priceInfo, setPriceInfo] = useState<PriceInfo | null>(null)

  // useEffect(() => {
  //   if (loading) return
  //   const languageId = isGloballyPricelist() ? 9 : KK_LANG_IDS[locale as Locale]
  //   const currencyOpt = getCurrencyOptByLanguageId(languageId, currencies)
  //   const filteredPromotions = promotions.filter(isPromotionValidForCatalog((catalogId as Catalog) || ''))
  //
  //   const newPriceInfo = getPrices(
  //     product,
  //     storeId as KK_STORE_TYPE,
  //     locale as string,
  //     filteredPromotions,
  //     currencyOpt,
  //     '1'
  //   )
  //
  //   setPriceInfo(newPriceInfo)
  // }, [locale, product, promotions, storeId, catalogId, loading])
  const initPrice = useMemo(() => {
    const languageId = isGloballyPricelist() ? 9 : KK_LANG_IDS[locale as Locale]
    const currencyOpt = getCurrencyOptByLanguageId(languageId, currencies)
    const filteredPromotions = promotions.filter(isPromotionValidForCatalog((catalogId as Catalog) || ''))

    return getPrices(product, storeId as KK_STORE_TYPE, locale as string, filteredPromotions, currencyOpt, '1')
  }, [currencies, locale, product, promotions, storeId, catalogId])

  // console.log({ priceInfo })
  return initPrice
}
