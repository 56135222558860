import { IconTallink } from '@components/common/Header/HeaderIconTallink';
import { UpperNavigation } from '@components/common/Header/UpperNavigation';
import { CwsA, CwsHeader, CwsHeaderLogo, CwsHeaderMenu } from '@components/cws'
import { usePreloadedInitialDataData } from '@context/PreloadedInitialDataContext';
import Link from 'next/link';
import React from 'react'

/* ONLY ran by react server runtime */
/* This is only rendered on initial paint - disable JS to see it in action */
export const IntegratedHeaderPreloaded: React.FC = () => {
  const isPriceListApp = usePreloadedInitialDataData().isPricelist

  return (
    <>
      <CwsHeader>
        <UpperNavigation />
      </CwsHeader>
      <CwsHeaderMenu>
        <CwsHeaderLogo>
          <Link passHref href="/" legacyBehavior>
            <CwsA hideArrow>
              <IconTallink isPriceListApp={isPriceListApp} />
            </CwsA>
          </Link>
        </CwsHeaderLogo>
      </CwsHeaderMenu>
    </>
  )
}
