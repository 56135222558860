import { InitialDataType } from '@context/initialDataContext';
import { parseJsonOrOriginalString } from '@utils/json';
import type { AxiosPromise } from 'axios'
import axios from 'axios'
import * as R from 'ramda'
import type { LanguageId } from '@model/locales'
import type { KKError } from '@model/konakart/KKError'
import type { Promotion } from '@model/misc/Promotion'
import { getData, setData } from '@api/redis'
import type { CurrenciesInfo } from '@hooks/useCurrency'
import { makeAxiosConfig } from '@utils/axios-utils'
import type { Country } from '@model/order/Order'
import type { CouponCheckInput } from '@pages/api/check-gift-card'
import type { Currency } from '@model/product/PriceInfo'
import { KK_STORE } from '@api/apiConfig'
import type { Catalog } from "@model/pricelist/Catalog";

export const getAllPromotions = async (sessionId: string | undefined, languageId: LanguageId): Promise<Promotion[]> => {
  const cKey = `getAllPromotions-${KK_STORE}-${languageId}-${sessionId || 'guest'}`
  const cached = await getData<Promotion[]>(cKey)

  if (cached) {
    return cached
  }

  const config = makeAxiosConfig({
    f: 'getAllPromotions',
    s: KK_STORE,
    languageId,
    sessionId,
    market: 'et',
  })

  const { data } = await (axios(config) as AxiosPromise<{ r: Promotion[] } | KKError>)
  if ('r' in data) {
    await setData(cKey, data.r)
    return data.r
  } else {
    throw new Error(data.m)
  }
}

export const getCurrencies = async (languageId: LanguageId): Promise<CurrenciesInfo[]> => {
  const market = languageId === 7 ? 'sv' : 'et'
  const storeId = KK_STORE
  const cKey = `getCurrencies-${storeId}`
  const cached = await getData<CurrenciesInfo[]>(cKey)

  if (cached) {
    return cached
  }

  const config = makeAxiosConfig({ f: 'getAllCurrencies', s: KK_STORE, languageId, market })

  const { data } = await (axios(config) as AxiosPromise<{ r: CurrenciesInfo[] } | KKError>)
  if ('r' in data) {
    await setData(cKey, data.r)
    return data.r
  } else {
    throw new Error(data.m)
  }
}

export const getAllCountries = async (languageId: LanguageId, sessionId: string | undefined): Promise<Country[]> => {
  const cKey = `getAllCountries-${KK_STORE}-${languageId}-${sessionId ?? 'guest'}`
  const cached = await getData<Country[]>(cKey)

  if (cached) {
    return cached
  }

  const config = makeAxiosConfig({ f: 'getAllCountries', s: KK_STORE, languageId })

  const { data } = await (axios(config) as AxiosPromise<{ r: Country[] } | KKError>)
  if ('r' in data) {
    await setData(cKey, data.r)
    return data.r
  } else {
    throw new Error(data.m)
  }
}

export const getInitialData = async (languageId: LanguageId, catalogId: Catalog, sessionId: string | undefined): Promise<InitialDataType> => {
  const cKey = `getInitialData-${KK_STORE}-${languageId}-${catalogId}-${sessionId ?? 'guest'}`
  const cached = await getData<InitialDataType>(cKey)

  if (cached) {
    /*  TODO: This can be removed at some point - string was added to redis, i fixed it so it adds parsedObject.
    *   But need to have clean cached object for it to work */
    return parseJsonOrOriginalString(cached as any)
  }

  const input2Obj = {
    languageId: `${languageId}`,
    catalogId: catalogId || 'shop',
    ...(sessionId && { sessionId }),
  }

  const getProductBody = {
    f: 'custom',
    s: KK_STORE,
    languageId,
    input1: 'getInitialData',
    input2: JSON.stringify(input2Obj, null, 0),
  }

  const config = makeAxiosConfig(getProductBody)

  const { data } = await (axios(config) as AxiosPromise<{ r: string } | KKError>)

  if ('r' in data) {
    const parsed = JSON.parse(data.r) as InitialDataType
    await setData(cKey, parsed)
    return parsed;
  } else {
    throw new Error(data.m)
  }
}

export interface SuccessCouponCheckResult {
  coupon: string
  balance: number
  price: number
  currency: Currency
  status: 'OK'
  validTo: string
}

export interface ErrorCouponCheckResult {
  errorCode: string
  errorMessage: string
  status: 'ERROR'
}
type CouponCheckResult = string

export const checkCouponById = async (body: CouponCheckInput): Promise<CouponCheckResult> => {
  const config = makeAxiosConfig({
    f: 'custom',
    s: KK_STORE,
    languageId: body.languageId,
    input1: 'findCouponById',
    input2: JSON.stringify(R.omit(['languageId'], body)),
  })

  const { data } = await (axios(config) as AxiosPromise<{ r: CouponCheckResult } | KKError>)
  if ('r' in data) {
    return data.r
  } else {
    throw new Error(data.m)
  }
}

interface MultipleCouponsCheckResult {
  status: 'OK' | 'ERROR'
  couponsNotValid: string[]
}

export type CouponsCheckInput = Pick<CouponCheckInput, 'code' | 'currency'>

export const checkAllCoupons = async (body: CouponsCheckInput[]): Promise<MultipleCouponsCheckResult> => {
  const kkRequestBody = {
    f: 'custom',
    input1: 'checkAllCoupons',
    input2: JSON.stringify(body),
  }
  const config = makeAxiosConfig(kkRequestBody)

  // console.log('--------------')
  // console.log(JSON.stringify(kkRequestBody, null, 2))
  // console.log('--------------')

  const { data } = await (axios(config) as AxiosPromise<{ r: CouponCheckResult } | KKError>)
  if ('r' in data) {
    return JSON.parse(data.r) as MultipleCouponsCheckResult
  } else {
    throw new Error(data.m)
  }
}
