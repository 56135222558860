import { Footer } from '@model/content/content';
import React, { createContext, FC, PropsWithChildren, useContext } from 'react';

export interface PreloadedFooterContextValue {
  footer: Footer;
  topNavigation: Record<string, string>;
  isPricelist: boolean;
}

export interface ComponentProps {
  serverSideInitialData: PreloadedFooterContextValue;
}

export const PreloadedInitialDataContext = createContext<ComponentProps | undefined>(undefined);

/* Preloaded initial data provides smaller subset of data - footer and header mostly */
export const PreloadedInitialDataProvider: FC<PropsWithChildren<ComponentProps>> = ({ children, serverSideInitialData }) => {
  return (
    <PreloadedInitialDataContext.Provider value={{ serverSideInitialData: serverSideInitialData }}>
      {children}
    </PreloadedInitialDataContext.Provider>
  );
};

export const usePreloadedInitialDataData = (): PreloadedFooterContextValue => {
  const context = useContext(PreloadedInitialDataContext);
  if (!context) {
    throw new Error('usePreloadedFooterData must be used within a PreloadedInitialDataProvider');
  }
  return context.serverSideInitialData;
};
