import { useSsrTranslationsMap } from '@context/PreloadedTranslationsContext';

export const useServerSideTranslation = () => {
  const ctx = useSsrTranslationsMap();
  const rootTranslate = (term: string) => ctx.serverSideTranslations[term];

  const translate = (term: string, params?: any) => {
    try {
      // @ts-ignore
      return rootTranslate(term, params) as string
    } catch (e) {
      return term
    }
  }

  return {
    translate,
  }
}
