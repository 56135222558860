import { always, tryCatch } from 'ramda'

const parser = (json: string) => JSON.parse(json)

export const parseJsonOrOriginalString = (json: string) => {
  try {
    return parser(json)
  } catch {
    return json
  }
}
export const parseJsonOrEmptyString = tryCatch(parser, always(''))
export const parseJsonOrEmptyObject = tryCatch(parser, always({}))
export const parseJsonOrEmptyList = tryCatch(parser, always([]))


