import winston from 'winston'
import type * as Transport from 'winston-transport'

let grayLogTransport: Transport | null = null

if (typeof window === 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const WinstonGraylog2 = require('winston-graylog2')

  const graylogOptions = {
    name: 'Graylog',
    level: process.env.LOG_LEVEL || 'info',
    graylog: {
      servers: [
        {
          host: process.env.GRAYLOG_HOST as string,
          port: parseFloat(process.env.GRAYLOG_PORT || '12201'),
        },
      ],
      facility: process.env.GRAYLOG_APPLICATION_NAME,
      bufferSize: 8192,
    },
    staticMeta: {
      application_environment: process.env.DEPLOY_ENV,
      application_name: process.env.GRAYLOG_APPLICATION_NAME,
      log_name: 'shopping_webshop_2',
    },
  }

  grayLogTransport = new WinstonGraylog2(graylogOptions) as Transport
}

const consoleTransport = new winston.transports.Console()

const loggerTransports: Transport[] = [consoleTransport]

if (grayLogTransport) {
  loggerTransports.push(grayLogTransport)
}

const logger = winston.createLogger({
  format: winston.format.combine(winston.format.timestamp(), winston.format.json({ maximumDepth: 5 })),
  level: process.env.LOG_LEVEL || 'info',
  transports: loggerTransports,
})

export default logger
