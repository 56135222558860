import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Typography } from '@ui/components'
import { Badge, PriceLine } from '..'
import moduleStyles from './ProductViewParameters.module.scss'
import type { Product } from '@model/product'
import clsx from 'clsx'
import { ProductViewVariants } from '../ProductViewVariants'
import type { Variant } from '@model/product/VariantsInfo'
import router, { useRouter } from 'next/router'
import { AppRoutes } from 'app/routes'
import { useCart } from '@hooks/useCart'
import { useWishlist } from '@hooks/useWishlist'
import { Mail } from './assets'
import { useSpecialPrice } from '@hooks/product'
import { getClubOnePointsAvailable, setTypicalProductInfo } from '@utils/product/product-info'
import { getFormattedPrice, getPriceByWeight, getMaxQuantity } from '@utils/price'
import { FreeProductBar } from '@components/product/FreeProductBar/FreeProductBar'
import { useTranslate } from '@hooks/useTranslation'
import { useMultipleItemsQuantity } from '@hooks/useMultipleItemsQuantity'
import * as R from 'ramda'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import { useCurrency } from '@hooks/useCurrency'
import { useAlert } from '@context/alert'
import {
  CwsA,
  CwsButton,
  CwsCounter,
  CwsDivider,
  CwsAlert,
  CwsH4,
  CwsP,
  CwsIcon,
  CwsGrid,
  CwsGridRow,
  CwsGridCol,
} from '@components/cws'

import { getPriceValueWithCurrency, revertBackToEURCurrency } from '@utils/currency'
import { getSlug } from '@utils/slugs'
import { OutOfStock } from '@components/product/ProductViewParameters/OutOfStock'
import Tooltip from '../../../ui/components/Tooltip/Tooltip'
import { isSkuForbiddenToAddToCart, oncePerBasket } from '@utils/product-utils'
import { CartEvent, gaEventCart, UserData } from '@lib/gtm'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import OnBoardAvailability from '@components/product/ProductViewParameters/OnBoardAvailability'
import BundleAndBulk from '@components/product/ProductViewParameters/BundleAndBulk'
import { CustomFacetNumber } from '@model/product/CustomFacet'
import { getPromotionsTag } from '@components/product/ProductCard/ProductTagsRow/tags-utils'
import { usePriceInfo } from '@hooks/usePriceInfo'
import { useAppState } from '@context/state'
import s from '@components/product/ProductViewParameters/ProductViewParameters.module.scss'
import { getFreeProductInfo } from '@utils/product/old-price-utils'
import { useInitialData } from '@context/initialDataContext'
import { isAlcoholicProduct } from '@utils/product/isAlcoholicProduct'

type DeliveryTab = 'home' | 'ferry'

export const cartIncludesGalaTicket = R.find(R.propEq('sku', '1234'))

export interface ProductViewParametersProps {
  product: Product
  activeCampaign?: boolean
}

export type BundleTypeTab = 'bundle' | 'single'

const clothesSizeToNumber = (size: string): number => {
  switch (size?.toUpperCase()) {
    case 'XXXXXS':
      return -6
    case 'XXXXS':
      return -5
    case 'XXXS':
      return -4
    case 'XXS':
      return -3
    case 'XS':
      return -2
    case 'S':
      return -1
    case 'M':
      return 0
    case 'L':
      return 1
    case 'XL':
      return 2
    case 'XXL':
      return 3
    case 'XXXL':
      return 4
    case 'XXXXL':
      return 5
    case 'XXXXXL':
      return 6
    default:
      return 0
  }
}

const ProductViewParameters: FC<ProductViewParametersProps> = (props) => {
  const { product, activeCampaign } = props
  const {
    initialData: { allPromotions, currencies },
  } = useInitialData()

  const priceInfo = usePriceInfo(product, allPromotions)

  const { variantInfo, status, ecwPrice, info } = product
  const {
    state: { customer },
  } = useAppState()
  const {
    locale,
    query: { catalogId = '' },
  } = useRouter()
  const { currency } = useCurrency()

  const [isOpenCheckEmail, setIsOpenCheckEmail] = useState<boolean>(false)
  const languageId = KK_LANG_IDS[locale as Locale]
  const enhancedProduct = setTypicalProductInfo(product, languageId, allPromotions)

  const [bundleTypeTab, setBundleTypeTab] = useState<BundleTypeTab>('bundle')

  const isAlcoholItem = isAlcoholicProduct(product.custom3)

  // const variantsType = variantInfo?.type?.toLowerCase().includes('size') ? 'size' : 'colors'
  const { cart: cartItems = [] } = useCart('shopping cart')

  const isGalaTicketInBasket = useMemo(
    () => oncePerBasket(product.sku) && Array.isArray(cartItems) && Boolean(cartIncludesGalaTicket(cartItems)),
    [cartItems, product]
  )

  const hasVariants = variantInfo && Object.keys(variantInfo).length > 0 && (variantInfo.variants?.length ?? 0) > 1

  const variants = variantInfo?.variants

  const variantsArray = useMemo(() => {
    return variants?.sort((a, b) => clothesSizeToNumber(a.size) - clothesSizeToNumber(b.size)).reverse() ?? []
  }, [variants])

  const [selectedVariant, setSelectedVariant] = useState(
    variantsArray?.find((variant) => product.id === variant.productId) ?? variantsArray?.[0]
  )
  const maxQuantity = selectedVariant ? selectedVariant.quantity : product.quantity
  const availableQuantity = maxQuantity

  const { itemsQuantityState, dispatch } = useMultipleItemsQuantity(product.sku)

  const itemsQuantityAndPrices = useMemo(() => {
    const skusInUse = Object.keys(itemsQuantityState)

    return priceInfo.bundleIncludedProducts?.reduce(
      (acc, val) => ({
        ...acc,
        ...(skusInUse.includes(val.sku) && {
          [val.sku]: [
            itemsQuantityState[val.sku],
            getPriceValueWithCurrency(val.price0 || 0, currency, currencies) * itemsQuantityState[val.sku],
          ],
        }),
      }),
      {} as Record<string, number[]>
    )
  }, [itemsQuantityState, priceInfo.bundleIncludedProducts])

  const { translate } = useTranslate()

  useEffect(() => {
    setIsOpenCheckEmail(false)
  }, [locale])

  const handleQuantityUpdate = useCallback(
    (value: number) => {
      dispatch({
        type: 'Set',
        payload: {
          sku: product.sku,
          value,
        },
      })
    },

    [product, dispatch]
  )
  const reset = useCallback(() => {
    dispatch({
      type: 'ReSet',
      payload: {
        sku: product.sku,
        value: itemsQuantityState[product.sku],
      },
    })
  }, [product, dispatch, itemsQuantityState])

  const itemQuantityCallback = useCallback(
    (sku: string, value: number) => {
      dispatch({
        type: 'Set',
        payload: {
          sku,
          value,
        },
      })
    },
    [dispatch]
  )

  const currentProductQuantity = useMemo(() => itemsQuantityState[product.sku] || 1, [itemsQuantityState, product.sku])

  const allProductsQuantity = useMemo(() => {
    const { bundleQuantity, bundleFreeProducts } = priceInfo
    const quantityList = Object.keys(itemsQuantityState)
      .filter((sku) => (bundleTypeTab === 'single' ? product.sku === sku : true))
      .map((sku) => itemsQuantityState[sku])

    const realQuantity = R.sum(quantityList)

    if (!bundleQuantity) return realQuantity

    // bundle price calculations
    const freeProductMultiplier = Math.floor(realQuantity / bundleQuantity)
    const freeProducts = freeProductMultiplier * bundleFreeProducts

    return realQuantity - freeProducts
  }, [itemsQuantityState, priceInfo, bundleTypeTab, product.sku])

  const allQuantities = useMemo(() => {
    const quantityList = Object.keys(itemsQuantityState)
      .filter((sku) => (bundleTypeTab === 'single' ? sku === product.sku : true))
      .map((sku) => itemsQuantityState[sku])
    return R.sum(quantityList)
  }, [itemsQuantityState, product.sku, bundleTypeTab])

  const allPriceSum = useMemo(() => {
    if (!itemsQuantityAndPrices) return 0

    const quantityList = Object.keys(itemsQuantityAndPrices)
      .filter((sku) => (bundleTypeTab === 'single' ? sku === product.sku : true))
      .map((sku) => itemsQuantityAndPrices[sku][1])
    return R.sum(quantityList)
  }, [itemsQuantityAndPrices, product.sku, bundleTypeTab])

  const { addToCartWithSideEffects, cart } = useCart('shopping cart')

  const { addToWishlist, wishlist } = useWishlist()

  const { showAlert } = useAlert()
  const cartItemsQuantity = cart?.reduce(
    (acc, val) => ({
      ...acc,
      [val.sku]: val.quantity,
    }),
    {} as Record<string, number>
  )

  const handleAddToCart = useCallback(async () => {
    const cartItemQuantity = itemsQuantityState[product.sku]
    const currentCartItemQuantity = cartItemsQuantity[product.sku]
    const skus = Object.keys(itemsQuantityState).filter((sku) =>
      bundleTypeTab === 'single' ? sku === product.sku : itemsQuantityState[sku]
    )
    try {
      if (priceInfo.bundleIncludedProducts?.length) {
        for (const sku of skus) {
          if (cartItemsQuantity[sku] + itemsQuantityState[sku] > maxQuantity) {
            showAlert({ text: translate('basket.items.add.error.general'), error: true })
          }
          const bundleProduct = (priceInfo.bundleIncludedProducts || []).find(({ sku: bSku }) => bSku === sku)
          if (bundleProduct) {
            try {
              await addToCartWithSideEffects(
                {
                  productId: bundleProduct.id,
                  sku: bundleProduct.sku,
                  quantity: itemsQuantityState[sku],
                },
                () => {
                  gaEventAddToCart(bundleProduct, itemsQuantityState[sku])
                }
              )
            } catch (e) {
              //todo: here need an error toast
              // eslint-disable-next-line no-console
              console.error(`Error adding ${sku} to cart`, e)
            }
          }
        }
      } else {
        if (currentCartItemQuantity + cartItemQuantity > maxQuantity) {
          return showAlert({ text: translate('basket.items.add.error.general'), error: true })
        }
        await addToCartWithSideEffects({ productId: product.id, sku: product.sku, quantity: cartItemQuantity }, () => {
          gaEventAddToCart(product, cartItemQuantity)
        })
      }
    } catch (e) {
      //todo: here need an error toast
      // eslint-disable-next-line no-console
      console.error(`Error adding ${product.sku} to cart`, e)
    }
  }, [itemsQuantityState, addToCartWithSideEffects, product, priceInfo.bundleIncludedProducts, locale, currency])

  const handleAddToWishlist = useCallback(async () => {
    const skus = Object.keys(itemsQuantityState).filter((sku) =>
      bundleTypeTab === 'single' ? sku === product.sku : itemsQuantityState[sku]
    )
    for (const sku of skus) {
      if (sku && itemsQuantityState[sku]) {
        const bundleProduct = (priceInfo.bundleIncludedProducts || []).find(({ sku: bSku }) => bSku === sku)
        const productId = bundleProduct ? bundleProduct.id : product.id
        const alreadyAdded = R.compose(
          R.propOr(0, 'quantityDesired'),
          R.find(R.propEq('productId', productId))
        )(wishlist)
        const quantity = itemsQuantityState[sku] + (alreadyAdded as number)
        const added = await addToWishlist({
          productId,
          quantityDesired: quantity,
          product: { sku },
        })
        if (added) {
          showAlert({ text: translate('s2.wishlist.items.added') as string, hideDelay: 4000 })
          try {
            gaEventAddToWishlist(product, quantity)
          } catch (e) {
            if (e instanceof Error) console.warn(e.message)
          }
        } else {
          showAlert({ error: true, text: translate('basket.items.add.error.general') as string, hideDelay: 4000 })
        }
      }
    }
  }, [
    addToWishlist,
    product,
    itemsQuantityState,
    showAlert,
    translate,
    locale,
    currency,
    priceInfo.bundleIncludedProducts,
    wishlist,
    bundleTypeTab,
  ])

  // const [selectedTab, setSelectedTab] = useState<DeliveryTab>('home')
  //
  // const handleSelectDeliveryTab = useCallback((key: DeliveryTab) => {
  //   setSelectedTab(key)
  // }, [])
  //
  // const homeDeliveryNode = (
  //   <>
  //     <Dropdown label="Destination:" />
  //   </>
  // )
  //
  // const ferryDeliveryNode = (
  //   <>
  //     <Dropdown label="Route:" />
  //     <Dropdown label="Ship:" />
  //     <div className="flex flex-col space-y-3">
  //       <RadioButton label="Megastar" checked={true} onChange={() => {}} />
  //       <RadioButton label="Star" checked={false} onChange={() => {}} />
  //     </div>
  //     <div className="py-2">
  //       <hr />
  //     </div>
  //     <div className="flex flex-row space-x-2 justify-start">
  //       <Info />
  //       <Typography as="p" variant="body" className="flex-1">
  //         Tallink cannot guarantee the availability of the product during your trip.
  //       </Typography>
  //     </div>
  //   </>
  // )

  const handleSelectVariant = useCallback(
    (variant: Variant) => {
      setSelectedVariant(variant)

      const urlSegments = variant.url.split('-')
      let variantSku = urlSegments[urlSegments.length - 1]

      if (urlSegments.includes('W') && variant.url.includes('W-')) {
        variantSku = `W-${variantSku}`
      } else if (urlSegments.includes('L') && variant.url.includes('L-')) {
        variantSku = `L-${variantSku}`
      }
      const name = variant.name.toLowerCase().split(' ').join('-')
      const productSlug = getSlug(name, variantSku)

      const href = catalogId
        ? `/${catalogId}${AppRoutes.productDetails.get(productSlug)}`
        : AppRoutes.productDetails.get(productSlug)

      router
        .replace(href, undefined, {
          scroll: false,
        })
        .catch(console.error)
    },
    [catalogId]
  )

  const isOutOfStock = status === 0 || !availableQuantity || availableQuantity < 1

  const { normalPrice, topPrice } = priceInfo
  const discountedPrice = useMemo(() => (normalPrice > topPrice ? topPrice : 0), [normalPrice, topPrice])
  const priceByWeight = getPriceByWeight(translate)(product.model, topPrice, currency)

  let { promoPrice } = enhancedProduct

  if (promoPrice) promoPrice = getPriceValueWithCurrency(promoPrice, currency, currencies)

  let specialPrice = useSpecialPrice(product) ? promoPrice || priceInfo.specialPrice : 0

  if (discountedPrice && !specialPrice) specialPrice = discountedPrice

  // todo: move this price dance to the hook

  // the price shown on top, midnight
  const basePrice = useMemo(() => {
    if (priceInfo.bundleType) return allQuantities >= priceInfo.bundleQuantity ? priceInfo.topPrice : normalPrice
    return specialPrice || normalPrice
  }, [normalPrice, specialPrice, priceInfo.bundleType, priceInfo.topPrice, allQuantities, priceInfo.bundleQuantity])
  const promotionInfo = useMemo(() => {
    return product?.productPromotion.find((promo) => promo.id === product.promotionResults?.[0]?.promotionId)
  }, [product?.productPromotion, product.promotionResults])

  const promotionPrice = useMemo(() => {
    if (!promotionInfo) return 0
    return Number(promotionInfo.custom2) > allQuantities ? normalPrice : specialPrice
  }, [promotionInfo, specialPrice, normalPrice, allQuantities])

  const gaEvent = (product: Product, quantity: number, eventName: CartEvent) => {
    try {
      const campaign = product.customAttrArray?.find((attr) => attr.facetNumber === CustomFacetNumber.Campaign)?.value
      const label = product.customAttrArray?.find((attr) => attr.facetNumber === CustomFacetNumber.Label)?.value
      let { bundleLabel = '' } = product.priceInfo ?? { bundleLabel: '' }
      const { promotionName } = getPromotionsTag(allPromotions)(product.promotionResults)
      const badge = promotionName || campaign || bundleLabel || label || ''
      const productUrlArr = product.url.split('/')

      const category = productUrlArr[1]
      const category2 = productUrlArr[2]
      const discount = normalPrice - basePrice
      const item = {
        item_name: product.name,
        item_id: product.sku,
        price: normalPrice.toString(),
        discount: discount ? discount.toFixed(2).toString() : '',
        currency: currency,
        promotion_name: translate(badge),
        item_brand: product.manufacturerName,
        item_category: category ?? '',
        item_category2: category2 ?? '',
        quantity: quantity?.toString(),
        ...(eventName === 'add_to_cart' ? { index: 1, item_list_id: 'detail' } : {}),
      }
      const user: UserData | null = customer
        ? {
            user_email: customer.emailAddr,
            user_phone: customer.telephoneNumber,
            user_id: customer.id,
          }
        : null
      gaEventCart(eventName, item, user, basePrice)
    } catch (e) {
      console.error(e)
    }
  }

  const gaEventAddToCart = (product: Product, quantity: number) => {
    gaEvent(product, quantity, 'add_to_cart')
  }
  const gaEventAddToWishlist = (product: Product, quantity: number) => {
    gaEvent(product, quantity, 'add_to_wishlist')
  }
  const gender = (product.customAttrArray || []).find(({ name }) => name === 'gender')
  const totalPriceBeforeCo = useMemo(
    () => (promotionPrice || basePrice) * allProductsQuantity,
    [basePrice, allProductsQuantity, promotionPrice]
  )

  const calculatedPrice = useMemo(() => {
    if (!priceInfo.bundleQuantity) return totalPriceBeforeCo

    const numberOfBundles = Math.floor(allQuantities / priceInfo.bundleQuantity)

    if (numberOfBundles && itemsQuantityAndPrices && !isGloballyPricelist()) {
      const pricesList = Object.keys(itemsQuantityAndPrices)
        .filter((sku) => (bundleTypeTab === 'single' ? sku === product.sku : itemsQuantityAndPrices[sku][1]))
        .map((sku) => itemsQuantityAndPrices[sku][1])
      const pricesSum = R.sum(pricesList)

      if (Object.keys(itemsQuantityAndPrices).length <= 1) {
        return pricesSum - basePrice * numberOfBundles
      }
      const priceOnOneProducts = Object.keys(itemsQuantityAndPrices)
        .filter((sku) => (bundleTypeTab === 'single' ? sku === product.sku : itemsQuantityAndPrices[sku][1]))
        .map((sku) => itemsQuantityAndPrices[sku][1] / itemsQuantityAndPrices[sku][0])
        .sort((a, b) => a - b)
      const totalPriceOfFree = R.sum(priceOnOneProducts.splice(0, numberOfBundles))

      return pricesSum - totalPriceOfFree
    }

    if (isGloballyPricelist()) {
      const leftOverProductPrices = (allQuantities - numberOfBundles * priceInfo.bundleQuantity) * priceInfo.topPrice
      if (numberOfBundles) return numberOfBundles * priceInfo.bundlePrice + leftOverProductPrices
    }

    if (priceInfo.bundleQuantity && allQuantities < priceInfo.bundleQuantity) {
      // return priceInfo.normalPrice * allQuantities
      return priceInfo.bundleIncludedProducts?.length ? allPriceSum : priceInfo.normalPrice * allQuantities
    }
    return priceInfo.topPrice * allQuantities
  }, [
    priceInfo.bundleQuantity,
    priceInfo.bundlePrice,
    priceInfo.topPrice,
    priceInfo.bundleIncludedProducts,
    priceInfo.normalPrice,
    totalPriceBeforeCo,
    allQuantities,
    allPriceSum,
    bundleTypeTab,
  ])

  const spendableCoPoints = useMemo(() => {
    const result = getClubOnePointsAvailable(totalPriceBeforeCo, product.priceInfo.coRate, parseFloat(product.custom7))
    if (currency !== 'EUR') {
      return revertBackToEURCurrency(result, currencies)
    }
    return result
  }, [totalPriceBeforeCo, priceInfo.coRate, product.custom7, currency, currencies])
  const totalPriceAfterCo = useMemo(
    () => totalPriceBeforeCo - (totalPriceBeforeCo * parseFloat(product.custom7 || '0')) / 100,
    [totalPriceBeforeCo, product.custom7]
  )

  const discount = useMemo(() => {
    return enhancedProduct.promotionResults
      ? enhancedProduct.promotionResults.find((item) => item.orderTotalCode === 'ot_df_product_discount')?.value
      : null
  }, [enhancedProduct.promotionResults])

  const discountValue = useMemo(() => {
    if (priceInfo.bundleType && priceInfo.bundleSavePercent && allQuantities >= priceInfo.bundleQuantity) {
      return `-${priceInfo.bundleSavePercent}%`
    }
    if (discount) {
      return `-${discount}%`
    }
    return priceInfo.youSavePercent ? `-${priceInfo.youSavePercent}%` : ''
  }, [
    priceInfo.bundleType,
    priceInfo.bundleSavePercent,
    priceInfo.bundleQuantity,
    priceInfo.youSavePercent,
    allQuantities,
    discount,
  ])
  const { promotionName: mainPromotion, description: promotionDescription } = useMemo(
    () => getPromotionsTag(allPromotions)(product.promotionResults),
    [allPromotions, product.promotionResults]
  )
  const promotionDF =
    product.promotionResults &&
    product.promotionResults.find((promo) => promo.orderTotalCode === 'ot_df_product_discount')
  const specialPriceKey = promotionDF ? 'w2.product.tile.price.set' : 'w2.product.tile.price.normal'
  const infoNode = (
    <div className={clsx(s.readLeft, 'gap-4 py-[16px] px-6 flex flex-row items-center bg-seafoam')}>
      <CwsIcon icon="price-tag" size="24px" />
      <CwsP size="small" hideSpacing>
        {translate(promotionDescription ?? '')}
      </CwsP>
    </div>
  )
  const freeProductResults = getFreeProductInfo(product)

  let priceEur30dConverted
  if (ecwPrice?.priceEur30d)
    priceEur30dConverted = getPriceValueWithCurrency(ecwPrice?.priceEur30d, currency, currencies)

  return (
    <div className={clsx(moduleStyles.container)}>
      {/*{ecwPrice && <pre>ecw price: {ecwPrice.priceEur30d}</pre>}*/}
      {hasVariants && selectedVariant && (
        <>
          <ProductViewVariants
            variantsInfo={variantInfo}
            selectedVariant={selectedVariant}
            onSelectVariant={handleSelectVariant}
          />
          <hr />
        </>
      )}
      {!!gender && (
        <>
          <div className={moduleStyles.gender}>
            <CwsP size="small">{translate('profile.details.gender')}: </CwsP>
            <CwsH4 size="lead" weight={500}>
              {translate(gender.value)}
            </CwsH4>
          </div>
          <hr />
        </>
      )}
      {((Boolean(specialPrice) || activeCampaign) && basePrice < normalPrice) ||
      (allQuantities >= priceInfo.bundleQuantity && priceInfo.bundleType) ? (
        <>
          <div className={'flex flex-col device600: py-4 space-y-lg'}>
            <div className="flex flex-col gap-y-2">
              <div className="flex gap-x-2 items-start device600:px-4 ">
                <CwsGrid className="cws-pt-xs" width="fluid">
                  <PriceLine
                    label={translate(specialPriceKey)}
                    value={basePrice}
                    valueColor={!priceInfo.bundleType ? 'notice' : 'midnight'}
                    discountValue={!isAlcoholItem && discountValue && isGloballyPricelist() ? discountValue : undefined}
                  />
                  {!isAlcoholItem && !priceInfo.bundleType && (
                    <CwsGridRow justifyContent="space-between">
                      <CwsGridCol>
                        <span className="flex gap-2 justify-end">
                          <CwsP size="small" color="dark-grey">
                            {translate('w2.product.title.regular')} :{' '}
                          </CwsP>
                          <CwsP size="small" weight="500" color="dark-grey cws-m-0">
                            {getFormattedPrice(normalPrice, currency)}
                          </CwsP>
                        </span>
                      </CwsGridCol>
                    </CwsGridRow>
                  )}
                </CwsGrid>
              </div>

              {Boolean(priceByWeight && !isAlcoholItem) && (
                <span className="flex justify-end device600:px-4">
                  <div className={clsx(moduleStyles.litrePrice)}>
                    <CwsP size="small" hideSpacing>
                      {priceByWeight}
                    </CwsP>
                  </div>
                </span>
              )}
            </div>
          </div>
          <hr />
        </>
      ) : (
        <>
          <div className={clsx(moduleStyles.section, moduleStyles.priceSection)}>
            <CwsGrid className="cws-py-xs" width="fluid">
              <PriceLine label={translate('w2.product.tile.price.normal')} value={normalPrice} />
            </CwsGrid>
            {Boolean(priceByWeight) && (
              <div className={clsx(moduleStyles.litrePrice)}>
                <CwsP size="small" hideSpacing>
                  {priceByWeight}
                </CwsP>
              </div>
            )}
          </div>
          <hr />
        </>
      )}
      {Boolean(isGloballyPricelist() ? priceInfo.bundleType : product.priceInfo.bundleType) ? (
        <BundleAndBulk
          product={product}
          itemsQuantityState={itemsQuantityState}
          handleQuantityUpdate={handleQuantityUpdate}
          itemQuantityCallback={itemQuantityCallback}
          productQuantity={currentProductQuantity}
          bundleTypeTab={bundleTypeTab}
          setTab={setBundleTypeTab}
          reset={reset}
        />
      ) : (
        <div className={moduleStyles.section}>
          <div className={moduleStyles.parameter}>
            <Typography>{translate('product.detail.quantity.title')}:</Typography>
            <CwsCounter
              insert
              id={'product-quantity'}
              onChange={handleQuantityUpdate}
              value={currentProductQuantity}
              max={oncePerBasket(product.sku) ? 1 : getMaxQuantity(maxQuantity)}
              min={1}
            />
          </div>
          {product.info?.isLowStock && !isOutOfStock && (
            <CwsAlert variant="inline" type="info">
              <CwsP size="small">{translate('product.detail.quantity.info')}</CwsP>
            </CwsAlert>
          )}
        </div>
      )}
      {Boolean(product.custom5) && <OnBoardAvailability shipsListString={product.custom5} />}
      <CwsDivider variant="solid" />
      {isGloballyPricelist() ? (
        <div className={moduleStyles.section}>
          <div className="flex flex-col space-y-sm">
            <CwsGrid className="cws-py-xs" width="fluid">
              <PriceLine label={`${translate('product.detail.price.total')}:`} value={calculatedPrice} />
            </CwsGrid>
          </div>
        </div>
      ) : (
        <div className={moduleStyles.section}>
          <div className="flex flex-col space-y-sm">
            <CwsGrid className="cws-py-xs" width="fluid">
              <CwsGridRow noGutterVertical justifyContent="space-between">
                <CwsGridCol col="auto">
                  <CwsP hideSpacing size="small">
                    {translate('product.detail.price.total')}
                  </CwsP>
                </CwsGridCol>
                <CwsGridCol col="auto">
                  <CwsH4 textAlign="right" size="lead">
                    {getFormattedPrice(calculatedPrice, currency)}
                  </CwsH4>
                </CwsGridCol>
              </CwsGridRow>
              <CwsGridRow justifyContent="right">
                <CwsGridCol col="auto">
                  <CwsP size="small" textAlign="right">
                    {translate('cart.info.total.or.label')}
                  </CwsP>
                </CwsGridCol>
              </CwsGridRow>
              <CwsGridRow justifyContent="right">
                <CwsGridCol col="auto">
                  <CwsH4 textAlign="right" size="lead">
                    {getFormattedPrice(totalPriceAfterCo, currency)} + {spendableCoPoints}{' '}
                    {translate('cart.copoints.available.points.after')}
                  </CwsH4>
                </CwsGridCol>
              </CwsGridRow>
            </CwsGrid>
          </div>
          {promotionDescription &&
            !freeProductResults &&
            !Boolean(isGloballyPricelist() ? priceInfo.bundleType : product.priceInfo.bundleType) &&
            infoNode}
          <div>
            <div className="flex flex-row space-x-sm">
              <CwsAlert variant="inline" type="info">
                <CwsP size="small">{translate('product.detail.co.tooltip')}</CwsP>
              </CwsAlert>
            </div>
          </div>
        </div>
      )}
      <FreeProductBar product={product} />
      {!isGloballyPricelist() && (
        <div className="flex flex-col space-y-sm items-stretch px-lg py-xl cws-bg--color-seafoam">
          {isOutOfStock ? (
            <>
              {isOpenCheckEmail ? (
                <OutOfStock productId={product.id} />
              ) : (
                <>
                  <div
                    className="
                         flex flex-row items-center space-x-2
                         text-midnight font-normal justify-center text-sm mb-lg
                         "
                  >
                    <CwsAlert variant="inline" type="info">
                      <CwsP size="small">{translate('product.detail.product.disabled')}</CwsP>
                    </CwsAlert>
                  </div>
                  <Button
                    variant="outline"
                    size="sm"
                    className="flex flex-row items-center justify-center"
                    onClick={() => setIsOpenCheckEmail(true)}
                  >
                    <Mail />
                    <span className="ml-2.5 pt-[1px]">{translate('product.details.subscribe.to.back.in.stock')}</span>
                  </Button>
                </>
              )}
            </>
          ) : (
            <div className="flex flex-col product-options">
              <CwsButton
                disabled={isGalaTicketInBasket}
                className={clsx(isSkuForbiddenToAddToCart(product.sku) && 'invisible')}
                size="medium"
                variant="primary"
                width="fluid"
                onClick={handleAddToCart}
              >
                {translate('common.add.to.cart')}
              </CwsButton>
              <CwsA locale={locale} textAlign="center" className="mt-4" onClick={handleAddToWishlist} href={null}>
                {translate('product.tile.add.to.shopping.list')}
              </CwsA>
            </div>
          )}
        </div>
      )}
      {isGloballyPricelist() && (
        <div className="flex flex-col space-y-sm items-stretch px-lg py-xl cws-bg--color-seafoam">
          <div className="flex flex-col product-options">
            <CwsButton
              disabled={isGalaTicketInBasket}
              size="medium"
              variant="primary"
              width="fluid"
              onClick={handleAddToWishlist}
            >
              {translate('product.tile.add.to.shopping.list')}
            </CwsButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductViewParameters

interface DeliveryTabsProps {
  tabs: { key: DeliveryTab; name: string }[]
  selectedTab: string
  onSelect: (key: DeliveryTab) => any
}

const DeliveryTabs: FC<DeliveryTabsProps> = (props) => {
  const { tabs, selectedTab, onSelect } = props

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-2 gap-x-px bg-medium-grey">
        {tabs.map(({ key, name }) => {
          return (
            <div
              key={key}
              className={clsx(
                moduleStyles['tab-button'],
                selectedTab === key ? 'bg-white' : `bg-group-grey ${moduleStyles['tab-button-shadow']}`
              )}
              onClick={onSelect.bind(this, key)}
            >
              {name}
            </div>
          )
        })}
      </div>
    </div>
  )
}
