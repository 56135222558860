import { getCampaignPeriodContents } from '@api/content';
import { getBranchNameWhenDevelopmentEnv } from '@api/getBranchNameWhenDevelopmentEnv';
import { getAllTranslation, getTranslation } from '@api/messages';
import { checkAllCoupons, checkCouponById, getAllCountries, getAllPromotions, getCurrencies, getInitialData } from '@api/misc';
import { getPreloadedInitialData } from '@api/serverSideInitialData';
import { getPreloadedSubsetTranslations } from '@api/serverSideTranslations';

/* TODO - break apart [translations_api..etc] when it gets bloated */
export const BASE_API = {
  getPreloadedInitialData,
  getCampaignPeriodContents,
  getBranchNameWhenDevelopmentEnv,
  getAllPromotions,
  getCurrencies,
  getAllCountries,
  getInitialData,
  checkCouponById,
  checkAllCoupons,
  /* i18n */
  getPreloadedSubsetTranslations,
  getAllTranslation,
  getTranslation,
}
