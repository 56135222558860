import ListItem from '@components/common/Header/CartListItem'
import { CategoriesView } from '@components/common/Header/CategoriesView'
import { IconTallink } from '@components/common/Header/HeaderIconTallink';
import { LanguageSelector } from '@components/common/Header/LanguageSelector'
import PriceListItem from '@components/common/Header/PriceListItem'
import { UpperNavigation } from '@components/common/Header/UpperNavigation'
import {
  CwsA,
  CwsAccordion,
  CwsAccordionItem,
  CwsAccordionItemBody,
  CwsAccordionItemHeader,
  CwsAlert,
  CwsButton,
  CwsDivider,
  CwsHeader,
  CwsHeaderLogo,
  CwsHeaderMenu,
  CwsHeaderMenuNav,
  CwsHeaderMenuNavItem,
  CwsHeaderProfile,
  CwsHeaderSearch,
  CwsHeaderSearchResultList,
  CwsHeaderSearchResultListItem,
  CwsHeaderSearchResultListLink,
  CwsHeaderShopping,
  CwsHeaderShoppingHeader,
  CwsHeaderShoppingList,
  CwsHeaderWrapper,
  CwsIcon,
  CwsP,
  CwsSection,
} from '@components/cws'
import type { BundleInfo } from '@components/profile/Wishlist/Wishlist'
import { useInitialData } from '@context/initialDataContext'
import { useAppState } from '@context/state'
import { useCart } from '@hooks/useCart'
import { useCurrency } from '@hooks/useCurrency'
import { useDebouncedValue } from '@hooks/useDebounce'
import { useTranslate } from '@hooks/useTranslation'
import { useWishlist } from '@hooks/useWishlist'
import { useWishListPriceInfos } from '@hooks/useWishListPriceInfos'
import type { CategoryTip } from '@model/category/CategoryTip'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import type { Suggestion } from '@model/misc/Suggestion'
import { useMediaLess } from '@ui/hooks/useMediaLess'
import { device600, device800, useResize } from '@ui/hooks/useResize'
import { getFormattedPrice } from '@utils/price'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import { getSlug, prepareSearchTerm, SEPARATOR } from '@utils/slugs'
import axios from 'axios'


import { useRouter } from 'next/dist/client/router'
import Link from 'next/link'
import * as R from 'ramda'
import type { ChangeEventHandler, FC, KeyboardEventHandler } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import sanitize from 'sanitize-html'
import useSWR from 'swr'
import s from './IntegratedHeader.module.scss'

export const SSOContainer = () => <div id="sso-container" className={s.ssoWrapper}></div>

const getBrandNameFromRawText = (text: string): { brand: string; category?: string } => {
  try {
    return JSON.parse(text)
  } catch (e) {
    return {
      brand: '',
    }
  }
}

const SubAccordionItem: FC<{ subCategory: CategoryTip; parentName: string }> = ({ subCategory, parentName }) => {
  const [isOpen, setOpen] = useState(false)

  const handleItemClick = () => {
    setOpen(!isOpen)
  }

  const {
    locale,
    query: { catalogId = '' },
  } = useRouter()
  const slug = getSlug(`${parentName}${SEPARATOR}${subCategory.name}`, subCategory.id)

  const href = {
    pathname: catalogId ? '/[catalogId]/category/[slug]' : '/category/[slug]',
    query: { catalogId, slug },
  }

  return (
    <CwsAccordion>
      <CwsAccordionItem open={isOpen} onClick={handleItemClick}>
        <CwsAccordionItemHeader label={subCategory.name} />
        <CwsAccordionItemBody>
          <CwsDivider className="cws-my-8" hidden />
          <Link href={href} passHref legacyBehavior>
            <CwsA hideArrow size="h4" locale={locale}>
              {subCategory.name}
            </CwsA>
          </Link>
          {subCategory.children
            .filter(({ numberOfProducts }) => numberOfProducts > 0)
            .map((cat) => {
              const slug = getSlug(`${parentName}${SEPARATOR}${subCategory.name}${SEPARATOR}${cat.name}`, cat.id)
              const href = {
                pathname: catalogId ? '/[catalogId]/category/[slug]' : '/category/[slug]',
                query: { catalogId, slug },
              }

              return (
                <>
                  <CwsDivider className="cws-my-8" hidden />
                  <Link href={href} passHref legacyBehavior>
                    <CwsA hideArrow locale={locale}>
                      {cat.name}
                    </CwsA>
                  </Link>
                </>
              )
            })}
        </CwsAccordionItemBody>
      </CwsAccordionItem>
    </CwsAccordion>
  )
}

const Accordion: FC<{ item: CategoryTip; locale: string }> = ({ item, locale }) => {
  return (
    <CwsAccordion mode="header" controllable={true}>
      {item.children
        .filter(({ numberOfProducts }) => numberOfProducts > 0)
        .map((subCategory) => {
          if (subCategory.children.length === 0) {
            return (
              <CwsAccordionItem key={subCategory.id}>
                <CwsAccordionItemHeader label={subCategory.name} locale={locale} />
              </CwsAccordionItem>
            )
          }

          return <SubAccordionItem key={subCategory.id} subCategory={subCategory} parentName={item.name} />
        })}
    </CwsAccordion>
  )
}
type IntegratedHeader = {
  header: boolean
  menu: boolean
}
export const IntegratedHeader: FC<IntegratedHeader> = ({ header, menu }) => {
  const { locale, push, pathname, query, asPath } = useRouter()
  const [isFocusSearch, setIsFocusSearch] = useState(false)
  const isMobile = useMediaLess(device600)
  const isMobileForProfile = useMediaLess(device800)
  // const isMobile = deviceSize && deviceSize <= device600
  const { translate } = useTranslate()

  const canShowSearchBar = useMemo(() => {
    if (isGloballyPricelist()) {
      return Boolean(query.catalogId)
    }

    return true
  }, [query])

  const { itemsCount, cart = [], isLoading } = useCart('shopping cart')
  const cartItems = cart.length > 3 ? cart.slice(0, 3) : cart
  const { itemsCount: wishlistCount, wishlist = [] } = useWishlist()
  const [totalPrices, setTotalPrices] = useState({})
  const [totalPricesWishlist, setTotalPricesWishlist] = useState({})
  const wishlistItems = wishlist.length > 3 ? wishlist.slice(0, 3) : wishlist

  const setTotalCartPrices = useCallback(
    (itemSku: string, price: number) => {
      const listSku = R.map(R.prop('sku'), cart)
      setTotalPrices((prevState) => {
        const filteredData = R.pick(listSku, prevState)
        return { ...filteredData, [itemSku]: price }
      })
    },
    [cart],
  )
  const setTotalWishlistPrices = useCallback((itemSku: string, price: number) => {
    setTotalPricesWishlist((prevState) => {
      return { ...prevState, [itemSku]: price }
    })
  }, [])
  const totalPriceWishlist: number = useMemo(() => {
    return R.sum(Object.values(totalPricesWishlist))
  }, [totalPricesWishlist])
  const totalPriceCart: number = useMemo(() => {
    return R.sum(Object.values(totalPrices))
  }, [totalPrices])

  const bundleInfos = useWishListPriceInfos()
  const bundleGroups = R.groupBy<BundleInfo>(R.prop('bundleLabel'))(bundleInfos)
  const canShowWishlist = useMemo(() => {
    if (isGloballyPricelist()) {
      return Boolean(query.catalogId) && Boolean(wishlistCount)
    }
    return Boolean(wishlistCount)
  }, [query, wishlistCount])
  const {
    state: { customer },
  } = useAppState()

  const { currency } = useCurrency()
  const {
    initialData: { currencies, categories, catalogs },
  } = useInitialData()

  const [cartLink, setCartLink] = useState('/cart')

  useEffect(() => {
    if (customer) {
      // reload the wishlist and cart
    }
  }, [customer])

  const openLegacyBasket = useCallback(() => {
    if (itemsCount) push(cartLink).catch(console.error)
  }, [cartLink, itemsCount, push])

  const openLegacyWishlist = useCallback(() => {
    if (wishlistCount)
      push(isGloballyPricelist() && query.catalogId ? `/${query.catalogId}/shopping-list` : `/my-wishlist`).catch(
        console.error,
      )
  }, [wishlistCount, push, query])

  const [searchValueState, setSearchValueState] = useState('')

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValueState(e.target?.value ?? '')
  }

  const searchValue = useDebouncedValue(searchValueState, 300)
  const searchValueUrl = useMemo(() => prepareSearchTerm(searchValue ?? ''), [searchValue])

  const languageId = KK_LANG_IDS[locale as Locale]

  const { data: searchResultData } = useSWR<Suggestion[]>(
    searchValueUrl && isFocusSearch && `/api/suggestions+${searchValueUrl}`,
    async () => {
      const { data } = await axios.post(`/api/suggestions`, {
        languageId,
        languageCode: locale,
        searchText: searchValue,
      })
      return data
    },
  )

  const searchHref = query.catalogId
    ? `/${locale}/${query.catalogId}/products/${searchValueUrl}`
    : `/${locale}/products/${searchValueUrl}`

  const handleSearchKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    async (e) => {
      const isEnterPressed = e.code?.toLowerCase() === 'enter'

      if (isEnterPressed) {
        setIsFocusSearch(false)
        if (isMobile) {
          window.location.href = query.catalogId
            ? `/${locale}/${query.catalogId}/products/${searchValueUrl}`
            : `/${locale}/products/${searchValueUrl}`
        } else {
          await push(query.catalogId ? `/${query.catalogId}/products/${searchValueUrl}` : `/products/${searchValueUrl}`)
        }
      }
    },
    [isMobile, locale, push, searchValueUrl, query],
  )

  const search = canShowSearchBar ? (
    <CwsHeaderSearch
      id="header-search"
      onChange={handleSearchChange}
      onKeyDown={handleSearchKeyDown}
      onFocus={() => {
        setIsFocusSearch(true)
      }}
      onClickOutside={() => {
        setIsFocusSearch(false)
      }}
      focus={isFocusSearch}
      onClickIcon={async () => {
        setIsFocusSearch(false)
        if (isMobile) {
          window.location.href = query.catalogId
            ? `/${locale}/${query.catalogId}/products/${searchValueUrl}`
            : `/${locale}/products/${searchValueUrl}`
        } else {
          await push(query.catalogId ? `/${query.catalogId}/products/${searchValueUrl}` : `/products/${searchValueUrl}`)
        }
      }}
    >
      {isFocusSearch && (
        <>
          <CwsHeaderSearchResultList>
            {searchResultData
              ?.filter((item) => Object.keys(JSON.parse(item.rawText)).length > 0)
              .slice(0, 3)
              .map((resultItem) => {
                const { productId, categoryId, manufacturerId } = resultItem

                let brandIdParam = ''

                if (manufacturerId > 0) {
                  const { brand } = getBrandNameFromRawText(resultItem.rawText)
                  if (brand) brandIdParam = `?brands=${brand}-${manufacturerId}`
                }

                const prefix = query.catalogId ? `/${locale}/${query.catalogId}` : `/${locale}`

                const href =
                  productId && productId > 0
                    ? `${prefix}/item/${productId}`
                    : categoryId && categoryId > 0
                      ? `${prefix}/category/${categoryId}${brandIdParam}`
                      : `${prefix}/products/${sanitize(resultItem.richText, { allowedTags: [] })}${brandIdParam}`
                return (
                  <CwsHeaderSearchResultListItem key={resultItem.id}>
                    <Link passHref href={href} legacyBehavior>
                      <CwsA hideArrow className="block" locale={locale}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: sanitize(resultItem.richText.replace(/<b>.*?<\/b>/, `<b>${searchValue}</b>`)),
                          }}
                        />
                      </CwsA>
                    </Link>
                  </CwsHeaderSearchResultListItem>
                )
              })}
            <CwsHeaderSearchResultListLink>
              <Link href={searchHref} passHref legacyBehavior>
                <CwsA color="buoy-link" size="small" locale={locale}>
                  {translate('nav.btn.search')}
                </CwsA>
              </Link>
            </CwsHeaderSearchResultListLink>
          </CwsHeaderSearchResultList>
        </>
      )}
    </CwsHeaderSearch>
  ) : null

  const productListCountRef = useRef<number>(0)

  const productListCount = useMemo(() => {
    if (isLoading) return productListCountRef.current
    const count =
      cart?.reduce((acc, cur) => {
        return cur.quantity + acc
      }, 0) ?? 0
    productListCountRef.current = count

    return count
  }, [isLoading, cart])

  const shoppingListLink =
    isGloballyPricelist() && query.catalogId ? `/${query.catalogId}/shopping-list` : '/my-wishlist'
  return (
    // <span className={!isOpenCategory ? s.headerMenu : ''}>
    <CwsHeaderWrapper mobile={isMobile} className={s.header}>
      {header && (
        <CwsHeader>
          <UpperNavigation />
          {isMobile && (
            <CwsHeaderLogo>
              <Link passHref href="/" legacyBehavior>
                <CwsA hideArrow locale={locale} theme="midnight" className="ml-3">
                  <CwsIcon
                    color="white"
                    height="24"
                    width="100"
                    icon={isGloballyPricelist() ? 'logo-tallink-silja-short' : 'logo-tallink-e-store-short'}
                  />
                </CwsA>
              </Link>
            </CwsHeaderLogo>
          )}

          <CwsSection
            className={isMobile ? 'cws-d-flex cws-p-8' : 'cws-align-items-flex-start cws-d-flex'}
            variant="div"
          >
            <CwsSection className="cws-d-flex cws-mr-32-md" variant="div">
              <LanguageSelector
                selectedLanguageCode={locale ?? 'en'}
                onSelectLanguage={(locale: Locale) => push({ pathname, query }, asPath, { locale })}
              />
              {((!isMobile && canShowWishlist) || (!isMobile && Boolean(itemsCount))) && (
                <CwsDivider className="cws-hidden-xs-sm" color="dark-grey" orientation="vertical" spacing="s" />
              )}
              <>
                {!isMobile && canShowWishlist && (
                  <>
                    <CwsHeaderShopping
                      className="cws-mr-24-md"
                      icon="wishlist"
                      tagNotification={Boolean(wishlistCount)}
                      color="write"
                    >
                      <CwsHeaderShoppingHeader
                        title={translate('wishlist.tile.wishlist')}
                        icon="wishlist"
                        count={
                          wishlist?.reduce((acc, cur) => {
                            return cur.quantityDesired + acc
                          }, 0) ?? 0
                        }
                      >
                        <Link href={shoppingListLink} passHref legacyBehavior>
                          <CwsA color="buoy-link">{translate('wishlist.view.all')}</CwsA>
                        </Link>
                      </CwsHeaderShoppingHeader>

                      <CwsHeaderShoppingList
                        price={getFormattedPrice(totalPriceWishlist, currency)}
                        moreItemsLabel={
                          wishlist.length > 3 ? translate('wishlist.more.items', { count: wishlist.length - 3 }) : ''
                        }
                      >
                        {wishlistItems.map((item) =>
                          item.product && item.quantityDesired ? (
                            <PriceListItem
                              bundleGroups={bundleGroups}
                              key={item.id}
                              item={item}
                              currency={currency}
                              isWishlist
                              currencies={currencies}
                              setTotalCartPrices={setTotalWishlistPrices}
                            />
                          ) : null,
                        )}
                      </CwsHeaderShoppingList>
                      <CwsSection className="cws-p-xs" variant="div">
                        <CwsButton width="fluid" onClick={openLegacyWishlist}>
                          {translate('wishlist.view.all')}
                        </CwsButton>
                      </CwsSection>
                      <CwsAlert className="hidden">
                        <CwsP hideSpacing size="small" weight="500">
                          Delivery to chosen destination is free! [translate]
                        </CwsP>
                      </CwsAlert>
                    </CwsHeaderShopping>
                  </>
                )}
                {!isMobile && Boolean(itemsCount) && (
                  <>
                    <CwsHeaderShopping
                      className="cws-mr-24-md"
                      icon="shopping"
                      color="write"
                      tagNotification={Boolean(itemsCount)}
                    >
                      <CwsHeaderShoppingHeader
                        count={productListCount}
                        title={translate('cart.tile.shoppingcart')}
                        icon="shopping"
                      >
                        <Link passHref href={cartLink} legacyBehavior>
                          <CwsA color="buoy-link">{translate('wishlist.view.all')}</CwsA>
                        </Link>
                      </CwsHeaderShoppingHeader>
                      <CwsHeaderShoppingList
                        price={getFormattedPrice(totalPriceCart, currency)}
                        moreItemsLabel={cart.length > 3 ? translate('cart.more.items', { count: cart.length - 3 }) : ''}
                      >
                        {cart?.map((item, idx) => {
                          return item.product ? (
                            <ListItem
                              key={item.id}
                              item={item}
                              currency={currency}
                              isWishlist={false}
                              currencies={currencies}
                              setTotalCartPrices={setTotalCartPrices}
                              elementIndex={idx}
                            />
                          ) : null
                        })}
                      </CwsHeaderShoppingList>
                      <CwsSection className="cws-p-xs" variant="div">
                        <CwsButton width="fluid" onClick={openLegacyBasket}>
                          {translate('basket.go.to.checkout')}
                        </CwsButton>
                      </CwsSection>
                    </CwsHeaderShopping>
                  </>
                )}
              </>
            </CwsSection>
          </CwsSection>
          {!isMobileForProfile ? (
            <CwsHeaderProfile>
              <SSOContainer />
            </CwsHeaderProfile>
          ) : (
            <SSOContainer />
          )}
        </CwsHeader>
      )}
      {menu && (
        <CwsHeaderMenu>
          {!isMobile && (
            <CwsHeaderLogo>
              <Link passHref href="/" legacyBehavior>
                <CwsA hideArrow locale={locale}>
                  <IconTallink isPriceListApp={isGloballyPricelist()} />
                </CwsA>
              </Link>
            </CwsHeaderLogo>
          )}
          <>
            {isMobile && canShowWishlist && (
              <>
                <CwsHeaderShopping icon="wishlist" count={wishlist.length} tagNotification={Boolean(wishlistCount)}>
                  <CwsHeaderShoppingHeader
                    title={translate('wishlist.tile.wishlist')}
                    icon="wishlist"
                    count={
                      wishlist?.reduce((acc, cur) => {
                        return cur.quantityDesired + acc
                      }, 0) ?? 0
                    }
                  >
                    <Link href={shoppingListLink} passHref legacyBehavior>
                      <CwsA color="buoy-link">{translate('wishlist.view.all')}</CwsA>
                    </Link>
                  </CwsHeaderShoppingHeader>

                  <CwsHeaderShoppingList price={getFormattedPrice(totalPriceWishlist, currency)}>
                    {wishlist?.map((item) =>
                      item.product && item.quantityDesired ? (
                        <PriceListItem
                          bundleGroups={bundleGroups}
                          key={item.id}
                          item={item}
                          currency={currency}
                          isWishlist
                          currencies={currencies}
                          setTotalCartPrices={setTotalWishlistPrices}
                          isMobile
                        />
                      ) : null,
                    )}
                  </CwsHeaderShoppingList>
                  <CwsSection className="cws-p-xs" variant="div">
                    <CwsButton width="fluid" onClick={openLegacyWishlist}>
                      {translate('wishlist.view.all')}
                    </CwsButton>
                  </CwsSection>
                  <CwsAlert className="hidden">
                    <CwsP hideSpacing size="small" weight="500">
                      Delivery to chosen destination is free! [translate]
                    </CwsP>
                  </CwsAlert>
                </CwsHeaderShopping>
              </>
            )}
            {isMobile && Boolean(itemsCount) && (
              <>
                <CwsHeaderShopping
                  // className="cws-mr-24-md"
                  icon="shopping"
                  color="write"
                  tagNotification={Boolean(itemsCount)}
                  // open={cartFlyoutOpen}
                  // setOpen={setCartFlyoutOpen}
                >
                  <CwsHeaderShoppingHeader
                    count={cart.reduce((acc, cur) => {
                      return cur.quantity + acc
                    }, 0)}
                    title={translate('cart.tile.shoppingcart')}
                    icon="shopping"
                  >
                    <Link passHref href={cartLink} legacyBehavior>
                      <CwsA color="buoy-link">{translate('wishlist.view.all')}</CwsA>
                    </Link>
                  </CwsHeaderShoppingHeader>
                  <CwsHeaderShoppingList price={getFormattedPrice(totalPriceCart, currency)}>
                    {cartItems?.map((item) => {
                      return item.product ? (
                        <ListItem
                          key={item.id}
                          item={item}
                          currency={currency}
                          isWishlist={false}
                          currencies={currencies}
                          setTotalCartPrices={setTotalCartPrices}
                          isMobile
                        />
                      ) : null
                    })}
                  </CwsHeaderShoppingList>
                  <CwsSection className="cws-p-xs" variant="div">
                    <CwsButton width="fluid" onClick={openLegacyBasket}>
                      {translate('basket.go.to.checkout')}
                    </CwsButton>
                  </CwsSection>
                </CwsHeaderShopping>
              </>
            )}
          </>
          {isGloballyPricelist() && !Boolean(categories.length) && Boolean(catalogs.length) && (
            <CwsHeaderMenuNav>
              {catalogs.map((ferry) => (
                <CwsHeaderMenuNavItem key={ferry.id} href={`/${locale}/${ferry.id}`} icon="ship" label={ferry.title}>
                  <CwsA href={`/${locale}/${ferry.id}`} />
                </CwsHeaderMenuNavItem>
              ))}
            </CwsHeaderMenuNav>
          )}
          {categories && <CategoriesView categories={categories} />}
          {search}
        </CwsHeaderMenu>
      )}
    </CwsHeaderWrapper>
    // </span>
  )
}
