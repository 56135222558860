import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  CwsA,
  CwsCounter,
  CwsGrid,
  CwsGridCol,
  CwsGridRow,
  CwsHeaderShoppingListItem,
  CwsHeaderShoppingListItemDeleteButton,
  CwsIcon,
  CwsP,
  CwsSpan,
} from '@components/cws'

import Image from 'next/image'
import { getFullImageUrl } from '@components/product/helpers'
import { useTranslate } from '@hooks/useTranslation'
import Link from 'next/link'
import debounce from 'lodash.debounce'
import { getSlug } from '@utils/slugs'
import { getFormattedPrice, getMaxQuantity } from '@utils/price'
import type { CartListItemProps } from '@components/common/Header/CartListItem'
import { useRouter } from 'next/router'
import { useWishlist } from '@hooks/useWishlist'
import { usePriceInfo } from '@hooks/usePriceInfo'
import * as R from 'ramda'
import { setTypicalProductInfo } from '@utils/product/product-info'
import { KK_LANG_IDS, Locale } from '@model/locales'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import { useInitialData } from '@context/initialDataContext'

export const PriceListItem: FC<CartListItemProps> = ({
  item,
  currency,
  currencies,
  bundleGroups,
  isMobile,
  setTotalCartPrices,
}) => {
  const { translate } = useTranslate()
  const { removeWishlistItem, addToWishlist, wishListId } = useWishlist()
  const { locale } = useRouter()
  const productSlug = getSlug(item.product?.name, item.product?.sku)

  const {
    initialData: { allPromotions },
  } = useInitialData()
  const priceInfo = usePriceInfo(item.product, allPromotions)
  const { topPrice, isClubOnePrice, normalPrice } = priceInfo
  setTypicalProductInfo(item.product, KK_LANG_IDS[locale as Locale], allPromotions)
  const {
    query: { catalogId },
  } = useRouter()

  const [localQuantity, setLocalQuantity] = useState(item.quantityDesired ?? 0)
  const maxQuantity = getMaxQuantity(item.quantityInStock)

  const isBundleApplied = useMemo(() => {
    if (!priceInfo.bundleLabel) return false

    if (
      (priceInfo.bundleLabel.includes('bundle') || priceInfo.bundleLabel.includes('.buy.')) &&
      bundleGroups &&
      priceInfo.bundleLabel in bundleGroups
    ) {
      const sum = R.compose(R.sum, R.map(R.prop('quantity')))(bundleGroups[priceInfo.bundleLabel])
      const numberOfBundles = Math.floor(sum / priceInfo.bundleQuantity)

      if (numberOfBundles >= 1) return true
    }
    return false
  }, [priceInfo, bundleGroups])

  const basePrice = useMemo(() => {
    if (!localQuantity) return 0
    if (isBundleApplied) {
      return priceInfo.bundlePricePerItem
    }
    return topPrice || normalPrice
  }, [normalPrice, topPrice, localQuantity, isBundleApplied, priceInfo.bundlePricePerItem])

  const shouldShowDiscountLabel = normalPrice !== basePrice

  const quantityEventHandler = useCallback(
    debounce(async (value: number) => {
      const { sku, productId } = item
      await addToWishlist({
        productId,
        quantityDesired: value,
        wishListId,
        product: {
          sku,
        },
      })
    }, 750),
    [item, wishListId]
  )

  const rowFullPrice = useMemo(() => {
    const numberOfBundles = Math.floor(localQuantity / priceInfo.bundleQuantity)
    const leftOverProductPrices = (localQuantity - numberOfBundles * priceInfo.bundleQuantity) * priceInfo.topPrice
    if (isGloballyPricelist()) {
      return numberOfBundles * priceInfo.bundlePrice + leftOverProductPrices
    }
    return basePrice * localQuantity
  }, [localQuantity, priceInfo, basePrice])

  const removeHandler = useCallback(async () => {
    const { id } = item
    await removeWishlistItem(id)
  }, [item, removeWishlistItem])

  useEffect(() => {
    setTotalCartPrices(item.product?.sku, rowFullPrice)
  }, [localQuantity])
  if (!item.product) return null

  return (
    <CwsHeaderShoppingListItem key={item.id} className="bg-white hover:bg-seafoam">
      <CwsA />
      <CwsGrid width="fluid">
        <CwsGridRow noGutter>
          <CwsGridCol className="cws-mr-xs" col="auto">
            {item.product.image && (
              <Image
                alt={item.product.manufacturerName}
                width="75"
                height="100"
                className="object-contain aspect-[1/1]"
                loading="eager"
                src={getFullImageUrl(item.product.image)}
              />
            )}
          </CwsGridCol>
          <CwsGridCol>
            <CwsGridRow noGutter verticalAlign="center">
              <CwsGridCol className="cws-header__shopping-list-item-description" col="12" sm="auto">
                <Link href={`/${catalogId}/product/${productSlug}`} passHref legacyBehavior>
                  <CwsA className="cws-mb-4" hideArrow>
                    {item.product.manufacturerName}
                  </CwsA>
                </Link>
                <CwsP hideSpacing size="small">
                  {item.product.name}
                </CwsP>
              </CwsGridCol>
              <CwsGridCol col="12" sm="fluid">
                <CwsGridRow justifyContent="space-between" noGutter verticalAlign="center">
                  <CwsGridCol col="auto">
                    <CwsCounter
                      size="small"
                      insert
                      value={localQuantity}
                      min={0}
                      max={maxQuantity}
                      onChange={(value: number) => {
                        setLocalQuantity(value)
                        value > 0 && quantityEventHandler(value)
                      }}
                    />
                  </CwsGridCol>
                  {localQuantity !== 0 && (
                    <CwsGridCol className="cws-ml-xs" col="auto">
                      <CwsP
                        hideSpacing
                        weight="500"
                        color={isClubOnePrice || shouldShowDiscountLabel ? 'notice' : 'midnight'}
                      >
                        {getFormattedPrice(rowFullPrice, currency)}
                      </CwsP>
                    </CwsGridCol>
                  )}
                  {localQuantity === 0 && (
                    <CwsGridCol col="auto" onClick={removeHandler}>
                      {!isMobile ? (
                        <CwsGridRow noGutter>
                          <CwsGridCol col="12" className=" flex justify-center items-center">
                            {/*<CwsSection onClick={removeHandler}>*/}
                            <CwsA color="error" hideArrow size="small" withIcon>
                              <CwsIcon className="cws-mr-6" icon="bin" size="16px" />
                            </CwsA>
                            {/*</CwsSection>*/}
                          </CwsGridCol>
                          <CwsGridCol col="12" className=" flex justify-center items-center">
                            <CwsA color="error" hideArrow size="small" withIcon>
                              <CwsSpan size="small" color="error" weight="500">
                                {translate('return.form.list.remove.child')}
                              </CwsSpan>
                            </CwsA>
                          </CwsGridCol>
                        </CwsGridRow>
                      ) : (
                        <CwsA color="error" hideArrow role="button" size="small" tabIndex="0" withIcon>
                          <CwsIcon icon="bin" size="16px" />
                          <CwsSpan weight="500">{translate('return.form.list.remove.child')}</CwsSpan>
                        </CwsA>
                      )}

                      {/*<CwsHeaderShoppingListItemDeleteButton onClick={removeHandler}>*/}
                      {/*  {translate('return.form.list.remove.child')}*/}
                      {/*</CwsHeaderShoppingListItemDeleteButton>*/}
                    </CwsGridCol>
                  )}
                </CwsGridRow>
              </CwsGridCol>
            </CwsGridRow>
          </CwsGridCol>
        </CwsGridRow>
      </CwsGrid>
    </CwsHeaderShoppingListItem>
  )
}

export default PriceListItem
