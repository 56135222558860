import { CwsGrid, CwsGridCol, CwsGridRow } from '@components/cws'
import { useTranslationsMap } from '@context/TranslationsContext';
import { Loader } from '@ui/components/Loader';
import React, { FC, PropsWithChildren } from 'react'

/* Show spinner until client side translations are fully loaded.
* Otherwise translation keys are hydrated and then fully re-rendered which we dont want */
const TranslationsLoader: FC<PropsWithChildren> = (props) => {
  const { isLoading } = useTranslationsMap();

  return (
    <>{isLoading ? <><CwsGrid width="fluid">
      <CwsGridRow>
        <CwsGridCol call={12} className="h-[30vh] flex justify-center">
          <div className="flex w-full h-[30vh] items-center justify-center absolute animate-spin">
            <Loader />
          </div>
        </CwsGridCol>
      </CwsGridRow>
    </CwsGrid>
    </> : props.children}</>
  )
}

export default TranslationsLoader
