import type { MessageKeyValue } from '@api/messages';
import { KK_LOCALE_MAP, Locale } from '@model/locales';
import { useRouter } from 'next/router';
import React, { createContext, useContext } from 'react';
import useSWRImmutable from 'swr/immutable';
import { KKDictionary } from '../additional';

interface TranslationsContextValue {
  isLoading: boolean;
  dictionary: KKDictionary;
}

export const TranslationsContext = createContext<TranslationsContextValue | undefined>(undefined);

export const TranslationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { locale } = useRouter();

  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('locale', KK_LOCALE_MAP[locale as Locale]);

  const { data, isLoading } = useSWRImmutable<MessageKeyValue>(`/api/translations?${urlSearchParams.toString()}`);
  const kkDictionary = {
    [locale as Locale]: data,
  } as KKDictionary;

  return (
    <TranslationsContext.Provider value={{ isLoading, dictionary: kkDictionary }}>
      {children}
    </TranslationsContext.Provider>
  );
};

export const useTranslationsMap = (): TranslationsContextValue => {
  const context = useContext(TranslationsContext);
  if (!context) {
    throw new Error('useTranslationsMap must be used within a TranslationsProvider');
  }
  return context;
};
