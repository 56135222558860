import type { FC, MouseEventHandler } from 'react'
import { useMemo, useState } from 'react'
import Image from 'next/image'
import type { Variant, VariantInfo } from '@model/product/VariantsInfo'
import { getFullImageUrl } from '../helpers'
import { SpoilerChevron } from '@ui/icons/common'
import clsx from 'clsx'
import CheckSvg from './assets/check.svg'
import s from './ProductViewVariants.module.scss'
import { useTranslate } from '@hooks/useTranslation'
import Link from 'next/link'
import { CwsA, CwsGrid, CwsGridCol, CwsGridRow, CwsP } from '@components/cws'

const getSizes = (variant: Variant[]) => {
  let startSize = 16
  return variant.map(() => {
    startSize += 6
    return { width: `${startSize}px`, height: `${startSize}px` }
  })
}

export interface ProductViewVariantsProps {
  variantsInfo: VariantInfo
  selectedVariant: Variant
  onSelectVariant?: (variant: Variant) => any
}

const clothesSizeToNumber = (size: string): number => {
  switch (size) {
    case 'XXXXXS':
      return -6
    case 'XXXXS':
      return -5
    case 'XXXS':
      return -4
    case 'XXS':
      return -3
    case 'XS':
      return -2
    case 'S':
      return -1
    case 'M':
      return 0
    case 'L':
      return 1
    case 'XL':
      return 2
    case 'XXL':
      return 3
    case 'XXXL':
      return 4
    case 'XXXXL':
      return 5
    case 'XXXXXL':
      return 6
    default:
      return 0
  }
}

const ProductViewVariants: FC<ProductViewVariantsProps> = (props) => {
  const { variantsInfo, selectedVariant, onSelectVariant } = props
  const { type } = variantsInfo

  const [isOpen, setIsOpen] = useState(false)

  const hasSizes = type.toLowerCase().includes('size')
  const hasColors = type.toLowerCase().includes('color')

  const hasSizeTable = type === 'Size-Parent'

  const variantsArray = useMemo(() => {
    if (!Number.isNaN(+(variantsInfo.variants?.[0].size ?? ''))) {
      return variantsInfo.variants?.sort((a, b) => +a.size - +b.size) ?? []
    }

    if (Boolean(parseInt(variantsInfo.variants?.[0].size ?? ''))) {
      return variantsInfo.variants?.sort((a, b) => parseInt(a.size) - parseInt(b.size)) ?? []
    }

    return variantsInfo.variants?.sort((a, b) => clothesSizeToNumber(a.size) - clothesSizeToNumber(b.size)) ?? []
  }, [variantsInfo.variants])

  const isExpandable = variantsArray.length > 3
  const variantsToRender = useMemo(() => {
    if (isExpandable && !isOpen) {
      return variantsArray.filter(({ url }) => !!url).slice(0, 3)
    }

    return variantsArray.filter(({ url }) => !!url)
  }, [variantsArray, isOpen, isExpandable])

  const hasLongVariantName = useMemo(() => {
    return !!variantsArray.find((variant) => (hasSizes ? variant.size.length > 10 : variant.color.length > 10))
  }, [variantsArray, hasSizes])

  const { translate } = useTranslate()

  const variantSizes = getSizes(variantsToRender)

  let counter = 1
  return (
    <div className="flex flex-col space-y-4 py-4">
      <CwsP size="small" className="device600:px-4">
        {hasSizes ? `${translate('variation.type.Size-All')}:` : `${translate('variation.type.Color-All')}:`}
      </CwsP>
      <CwsGrid width="fluid">
        <CwsGridRow>
          {variantsToRender.map((variant, index) => {
            // const isSelected = hasColors ? selectedVariant.color === variant.color : selectedVariant.size === variant.size
            const isSelected = selectedVariant.productId === variant.productId
            const isLast = index === variantsToRender.length - 1
            // const isThird = (index + 1) % 3 > 0
            let isBordered = false
            if (index === counter) {
              isBordered = true
              counter += 3
            }
            return (
              <CwsGridCol
                key={index}
                col={4}
                className={clsx(
                  hasSizes && 'mt-auto',
                  !isLast && isBordered && 'border-r border-l border-medium-grey',
                  isLast && isBordered && 'border-l border-medium-grey'
                )}
              >
                <div
                  className={clsx(
                    'flex flex-col items-center bg-white space-y-2 flex-shrink-0 flex-grow-1 cursor-pointer mt-auto device600:px-2 size-picker'
                  )}
                  onClick={onSelectVariant?.bind(this, variant)}
                >
                  <div
                    className={clsx(
                      'flex rounded-full bg-group-grey p-1 items-end',
                      hasSizes && 'mt-auto',
                      hasColors && 'h-10 mt-0',
                      hasColors && isSelected ? 'w-20 justify-between' : 'w-10',
                      isSelected && s['inner-shadow']
                    )}
                    style={hasSizes ? variantSizes[index] : {}}
                  >
                    {hasSizes && isSelected && <CheckSvg className="text-midnight m-auto" />}
                    {hasColors && (
                      <>
                        {isSelected && (
                          <div className="flex w-8 h-8 text-midnight items-center justify-center">
                            <CheckSvg />
                          </div>
                        )}
                        {variant.colorImg && (
                          <Image
                            alt="color icon"
                            src={getFullImageUrl(variant.colorImg)}
                            layout="fixed"
                            height={32}
                            width={32}
                            className="rounded-full"
                          />
                        )}
                      </>
                    )}
                  </div>
                  <span
                    className={clsx(
                      'text-center text-midnight mb-auto',
                      hasLongVariantName ? 'text-sm leading-4' : 'text-base leading-5',
                      isSelected ? 'font-medium' : 'font-normal'
                    )}
                  >
                    {variant[hasSizes ? 'size' : 'color']}
                  </span>
                </div>
              </CwsGridCol>
            )
          })}
        </CwsGridRow>
      </CwsGrid>

      {(isExpandable || hasSizeTable) && (
        <div className="flex flex-row flex-wrap justify-between font-medium text-sm leading-[18px] px-4 text-midnight underline">
          <CwsGrid width="fluid">
            <CwsGridRow>
              <CwsGridCol col={6}>
                {isExpandable && (
                  <span
                    className="flex flex-row items-center text-midnight cursor-pointer"
                    onClick={setIsOpen.bind(this, !isOpen)}
                  >
                    <SpoilerChevron className="inline-block mr-1.5" height={12} width={12} open={isOpen} />
                    <span>
                      {isOpen ? translate('filters.brandname.show.less') : translate('filters.brandname.show.all')}
                    </span>
                  </span>
                )}
              </CwsGridCol>
              <CwsGridCol col={6}>
                {hasSizeTable && (
                  <Link href="/support/size_charts" passHref legacyBehavior>
                    <CwsA color="midnight" inline size="small" hideArrow>
                      {translate('support.sizes.button')}
                    </CwsA>
                  </Link>
                )}
              </CwsGridCol>
            </CwsGridRow>
          </CwsGrid>
        </div>
      )}
    </div>
  )
}

export default ProductViewVariants
