import React, { createContext, FC, PropsWithChildren, useContext } from 'react';

interface SsrTranslationsContextValue {
  serverSideTranslations: Record<string, string>;
}

export const PreloadedTranslationsContext = createContext<SsrTranslationsContextValue | undefined>(undefined);


/* These are smaller set of translations that are loaded by the server in _app initialProps - to provide user with initial paint of the homepage */
/* The problem we're solving is that we are transmitting Excessive Translation Payloads - 400kb per load */
/* https://wiki.fleet.zone/display/WSHOP/Front-end+technical+debt+findings+draft */

export const PreloadedTranslationsProvider: FC<PropsWithChildren<SsrTranslationsContextValue>> = ({ children, serverSideTranslations }) => {

  return (
    <PreloadedTranslationsContext.Provider value={{ serverSideTranslations }}>
      {children}
    </PreloadedTranslationsContext.Provider>
  );
};

export const useSsrTranslationsMap = (): SsrTranslationsContextValue => {
  const context = useContext(PreloadedTranslationsContext);
  if (!context) {
    throw new Error('useTranslationsSsrMap must be used within a TranslationsProvider');
  }
  return context;
};
