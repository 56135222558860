import React, { createContext, useContext } from 'react'
import { useRouter } from 'next/router'
import type { CountryName, Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import axios from 'axios'
import type { CategoryTip } from '@model/category/CategoryTip'
import type { Footer } from '@model/content/content'
import type { TagGroup } from '@model/misc/TagGroup'
import type { CurrenciesInfo } from '@hooks/useCurrency'
import type { Promotion } from '@model/misc/Promotion'
import type { Country } from '@model/order/Order'
import type { CatalogFromContent } from '@api/content'
import useSWRImmutable from 'swr/immutable'
import { useAppState } from '@context/state'

export type InitialDataType = {
  categories: CategoryTip[]
  footerContent: Record<Locale, Footer>
  /* This is added later */
  footer: Footer
  tagGroups: TagGroup[]
  currencies: CurrenciesInfo[]
  topNavigation: Record<string, string>
  allPromotions: Promotion[]
  deliveryInfo: string
  allCountries: Country[]
  defaultDeliveryAddrIds: Record<CountryName, number>
  catalogs: CatalogFromContent[]
}

type InitialDataContextType = {
  initialData: InitialDataType
  isLoading: boolean
}

export const DefaultInitialData = () => ({
  categories: [],
  footerContent: null as any,
  footer: {
    bottomLinks: [],
    rows: [],
    columns: [],
  },
  tagGroups: [],
  currencies: [],
  topNavigation: {},
  allPromotions: [],
  deliveryInfo: '',
  allCountries: [],
  defaultDeliveryAddrIds: {
    Estonia: 0,
    Lithuania: 0,
    Latvia: 0,
    Sweden: 0,
    Finland: 0,
  },
  catalogs: [],
});

const InitialDataContext = createContext<InitialDataContextType>({
  initialData: DefaultInitialData(),
  isLoading: false,
})
export const InitialDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    locale,
    query: { catalogId = '' },
  } = useRouter()
  const {
    state: { customer },
  } = useAppState()

  const ssoToken = customer ? customer.ssoToken : null

  const languageId = KK_LANG_IDS[locale as Locale]

  const key = ssoToken
    ? `/api/initialData-${languageId}-${catalogId}-${ssoToken}`
    : `/api/initialData-${languageId}-${catalogId}`

  const { data: initialData = DefaultInitialData(), isLoading } = useSWRImmutable(key, async () => {
    const { data: initialData } = await axios.post('/api/initial-data', {
      languageId,
      ...(catalogId && { catalogId }),
    })

    const footer: Footer = initialData.footerContent[locale as Locale] as Footer
    return { ...initialData, footer }
  })

  return <InitialDataContext.Provider value={{ initialData, isLoading }}>{children}</InitialDataContext.Provider>
}

export const useInitialData = () => {
  const context = useContext(InitialDataContext)
  if (!context) {
    throw new Error('useInitialData must be used within a InitialDataProvider')
  }
  return context
}
