import { KK_STORE } from '@api/apiConfig'
import { BASE_API } from '@api/baseApi';
import { getData, setData } from '@api/redis'
import { InitialDataType } from '@context/initialDataContext';
import type { Footer } from '@model/content/content';
import type { LanguageId, Locale } from '@model/locales'

export type InitialDataPreloadedType = {
  footer: Footer
  topNavigation: Record<string, string>
}

export const getPreloadedInitialData = async (languageId: LanguageId, locale: string): Promise<InitialDataPreloadedType> => {
  const cKey = `getInitialData-preloaded-${KK_STORE}-${languageId}-guest`
  const cached = await getData<InitialDataPreloadedType>(cKey)

  if (cached) {
    return cached
  }

  const initialData = await BASE_API.getInitialData(languageId, 'shop', undefined) as InitialDataType;

  const initialDataFooterLinks = initialData.footerContent[locale as Locale] as Footer
  const initialDataTopNavigation = initialData.topNavigation as Record<string, string>
  const toBeCached = { footer: initialDataFooterLinks, topNavigation: initialDataTopNavigation };

  await setData(cKey, toBeCached)
  return toBeCached;
}

