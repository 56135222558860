import { CwsCarousel, CwsCarouselItem } from '@components/cws'
import { ProductCard } from '@components/product'
import type { Product } from '@model/product'
import clsx from 'clsx'
import type { FC } from 'react'
import s from './ProductSlider.module.scss'

export interface ProductSliderProps {
  products: Product[]
  mainPageCollection?: boolean
  listName: string
}

const ProductSlider: FC<ProductSliderProps> = (props) => {
  const { products, listName } = props

  const sliderSize: { [keys: string]: string } = {
    1: s.carousel_for_one,
    2: s.carousel_for_twe,
    3: s.carousel_for_free,
  }

  const nonListProducts = products.filter(e => !Array.isArray(e))

  return (
    <CwsCarousel mode={'card'} className={clsx(sliderSize[`${products.length}`])}>
      {nonListProducts.map((innerProduct, idx) => {
        const sku = products.map(({ sku }) => sku).join(',')

        return (
          <CwsCarouselItem key={sku}>
            <ProductCard listName={listName} product={innerProduct} className={s.sliderCard} index={idx + 1} />
          </CwsCarouselItem>
        );
      })}
    </CwsCarousel>
  )
}

export default ProductSlider
