import type { FC } from 'react'
import type { ImageProps } from 'next/image'
import Image from 'next/image'

const DEFAULT_HEIGHT = 128;
const DEFAULT_WIDTH = 324;

const ProductImage: FC<ImageProps> = (props) => {
  const { src, alt, ...restImageProps } = props
  const heightOrDefault = restImageProps.height || DEFAULT_HEIGHT
  const widthOrDefault = restImageProps.height || DEFAULT_WIDTH

  return (
      <Image
        alt={alt}
        height={heightOrDefault}
        width={widthOrDefault}
        src={src}
        quality={80}
        style={{ objectFit:"contain" }}
        className={"flex justify-center aspect-[2/1]"}
      />
  )
}

export default ProductImage
