import { useTranslationsMap } from '@context/TranslationsContext';
import { useG11n } from 'next-g11n'
import type { KKDictionary } from '../additional'

export const useTranslate = () => {
  const { dictionary } = useTranslationsMap();
  const { translate: rootTranslate } = useG11n<KKDictionary>(dictionary, true)

  const translate = (term: string, params?: any) => {
    try {
      // @ts-ignore
      return rootTranslate(term, params) as string
    } catch (e) {
      return term
    }
  }

  return {
    translate,
  }
}
