import type { MessageLocale } from '@model/locales'
import { makeAxiosConfig } from '@utils/axios-utils'
import type { AxiosPromise } from 'axios'
import axios from 'axios'
import type { KKError } from '@model/konakart/KKError'
import { expireLongerTimeInSeconds, getData, setData } from '@api/redis'
import { KK_STORE } from '@api/apiConfig'

export interface KKMessagesResponse {
  r: string
}

export type MessageKeyValue = Record<string, string>

export const getAllTranslation = async (locale: MessageLocale = 'et_EE'): Promise<MessageKeyValue> => {
  const cKey = `getTranslations-${locale}`
  const cached = await getData<MessageKeyValue>(cKey)

  if (cached) {
    return cached
  }

  const config = makeAxiosConfig({ f: 'custom', input2: locale, s: KK_STORE, input1: 'getMessages' })
  try {
    const { data } = await (axios(config) as AxiosPromise<KKMessagesResponse | KKError>)

    if ('r' in data) {
      const { r: messageJsonString } = data
      const result: MessageKeyValue = JSON.parse(messageJsonString)
      await setData(cKey, result, expireLongerTimeInSeconds)
      return result
    } else {
      throw new Error(data.m)
    }
  } catch (e) {
    console.error('[Messages] Error loading messages ', e)
    return {}
  }
}

export const getTranslation = async (locale: MessageLocale, key: string): Promise<string> => {
  const cKey = `getTranslation-${locale}-${key}`;
  const cached = await getData<string>(cKey);

  if (cached) {
    return cached;
  }

  const config = makeAxiosConfig({ f: 'getMsgValue', locale, type: 1, key });

  try {
    const { data } = await (axios(config) as AxiosPromise<KKMessagesResponse | KKError>);

    if ('r' in data) {
      const { r: message } = data;
      await setData(cKey, message, expireLongerTimeInSeconds); // Cache the result
      return message;
    } else {
      throw new Error(data.m);
    }
  } catch (e) {
    console.error(`[Messages] Error getting translation for key ${key} `, e);
    return key;
  }
};
