import type { FC, PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useMemo, useReducer, useRef } from 'react'
import type { CartState, CartStateContextProps } from '@components/cart/State/reducer'
import { cartStateReducer } from '@components/cart/State/reducer'
import { emptyData } from '@components/cart/delivery/CantactData/ContactData'
import type { Product } from '@model/product'

import { useRouter } from 'next/dist/client/router'
import { localStorageHelper } from '@utils/localStorageHelper'
import { defaultProtoType } from '@components/cart/delivery/DeliveryMethods/DeliveryPrototype'
import { useAppState } from '@context/state'
import type { Customer } from '@model/customer/Customer'
import type { Currency } from '@model/product/PriceInfo'
import { emptyPickUpPoint } from '@components/cart/delivery/DeliveryMethods/DeliverySubtype'
import type { Order } from '@model/order/Order'
import type { CustomerAddress } from '@api/addressCustomer'
import type { CountryName, Locale } from '@model/locales'
import { DELIVER_TO_COUNTRIES } from '@model/locales'
import { deliveryCountries } from '@model/locales'
import { cartIncludesWsBox } from '@components/cart/delivery/WrapAsGift/WrapAsGift'
import type { CartItem } from '@model/cart/CartItem'
import { useCart } from '@hooks/useCart'

export const CART_STATE_KEY = 'CART_STATE'

export const defaultCountry = 'Estonia'

const defaultAppState: CartState = {
  currentStep: 'cart',
  completedSteps: [],
  order: null,
  deliveryContactData: emptyData,
  giftRecipientContactData: emptyData,
  selectedDeliveryPoint: null,
  coupons: [],
  discountCoupons: [],
  paymentMethod: 'dibs',
  paymentTypeDetails: null,
  deliveryCountry: defaultCountry,
  deliveryMethodsByCountry: { Estonia: null, Finland: null, Latvia: null, Lithuania: null, Sweden: null },
  storedPaymentMethod: null,
  deliveryMethods: [],
  homeDeliveryAddress: null,
  bankPaymentResponse: null,
  redeemPoints: 0,
  outOfStockProducts: [],
  deliveryVariant: defaultProtoType[defaultCountry],
  orderSaveError: null,
  selectedPickUpPointId: emptyPickUpPoint.id,
  tempCustomerAddress: null,
  isGift: false,
}
export const CartContext = createContext<CartStateContextProps>({} as CartStateContextProps)

interface CartStateProviderProps extends PropsWithChildren {
  order: Order | null
  customerAddressBook: CustomerAddress[]
  giftWrapper?: Product
}

export const deliveryContactDataFromCustomer = (customer: Customer | null) => {
  if (customer)
    return {
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.emailAddr,
      telephone: customer.telephoneNumber,
      allFilled: true,
    }
  return emptyData
}

const getDeliveryCountryFromAddrBook = (
  customerAddressBook: CustomerAddress[],
  locale: Locale,
  customer?: Customer
): CountryName => {
  if (customer) {
    const country = customerAddressBook.find((ab) => ab.custom1 === 'shipping')?.countryName
    if (country && DELIVER_TO_COUNTRIES.includes(country)) {
      return country
    }
  }
  return deliveryCountries[locale as Locale]
}

const CartStateProvider: FC<CartStateProviderProps> = ({ children, order, giftWrapper, customerAddressBook }) => {
  const {
    state: { customer },
  } = useAppState()
  const prevState = useRef<CartState>()
  const {
    locale,
    query: { failed },
  } = useRouter()

  const value = {
    ...defaultAppState,
    order,
    deliveryCountry: deliveryCountries[locale as Locale],
    giftWrapperProduct: giftWrapper,
    ...(customer && {
      deliveryContactData: deliveryContactDataFromCustomer(customer),
      deliveryCountry: getDeliveryCountryFromAddrBook(customerAddressBook, locale as Locale, customer),
      tempCustomerAddress: customerAddressBook.find(({ custom1 }) => custom1 === 'temp') || null,
    }),
  }

  const [state, dispatch] = useReducer(cartStateReducer, value)
  const cartSateHistory: CartState = localStorageHelper.get(CART_STATE_KEY)
  const { removeItem, cart: cartItems = [] } = useCart('shopping cart')
  const { coupons } = state
  const currency: Currency = useMemo(() => (locale === 'sv' ? 'SEK' : 'EUR'), [locale])
  const currencyRef = useRef<Currency>(locale === 'sv' ? 'SEK' : 'EUR')

  useEffect(() => {
    return () => {
      localStorageHelper.remove(CART_STATE_KEY)
    }
  }, [])

  useEffect(() => {
    if (!state.isGift) {
      const theItem = cartIncludesWsBox(cartItems) as CartItem
      theItem && removeItem(theItem).then()
    }
  }, [state.isGift])

  useEffect(() => {
    if (prevState.current !== state) {
      localStorageHelper.set(CART_STATE_KEY, state)
    }
    prevState.current = state
  }, [state])

  useEffect(() => {
    if (currencyRef.current !== currency) {
      coupons.forEach((couponData) => dispatch({ type: 'remCoupon', payload: couponData.coupon }))
      localStorageHelper.set(CART_STATE_KEY, state)
    }

    currencyRef.current = currency
  }, [currency, coupons])

  useEffect(() => {
    if (customer && !cartSateHistory) {
      dispatch({
        type: 'fillContactData',
        payload: deliveryContactDataFromCustomer(customer),
      })
      dispatch({
        type: 'setDeliveryCountry',
        payload: getDeliveryCountryFromAddrBook(customerAddressBook, locale as Locale, customer),
      })
      dispatch({
        type: 'setTempAddress',
        payload: customerAddressBook.find(({ custom1 }) => custom1 === 'temp') || null,
      })
    }
  }, [customer, customerAddressBook, locale])

  useEffect(() => {
    if (cartSateHistory) {
      dispatch({ type: 'setAll', payload: cartSateHistory })
    } else {
      localStorageHelper.remove(CART_STATE_KEY)
      dispatch({ type: 'setAll', payload: value })
    }
  }, [])

  const exposed = {
    state,
    dispatch,
  }

  return <CartContext.Provider value={exposed}>{children}</CartContext.Provider>
}

export const useCartState = () => useContext(CartContext)
export default CartStateProvider
