import { useRouter } from 'next/dist/client/router'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import { getPrices } from '@utils/product/product-info'
import { getCurrencyOptByLanguageId } from '@hooks/useCurrency'
import { KK_STORE } from '@api/apiConfig'
import type { KK_STORE_TYPE } from '@pages/api/proxy'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import { isPromotionValidForCatalog } from '@utils/promotion'
import type { Catalog } from '@model/pricelist/Catalog'
import { useWishlist } from '@hooks/useWishlist'
import { useMemo } from 'react'
import { BundleInfo } from '@components/profile/Wishlist/Wishlist'
import { useInitialData } from '@context/initialDataContext'

export interface BundleInfoData extends BundleInfo {
  quantity: number
  sku: string
}

export const useWishListPriceInfos = (): BundleInfoData[] => {
  const {
    locale,
    query: { catalogId },
  } = useRouter()
  const {
    initialData: { currencies, allPromotions },
  } = useInitialData()
  const { wishlist } = useWishlist()

  const storeId = KK_STORE || isGloballyPricelist() ? 'store1' : 'store2'

  return useMemo(
    () =>
      wishlist.map((item) => {
        const languageId = KK_LANG_IDS[locale as Locale]
        const currencyOpt = getCurrencyOptByLanguageId(languageId, currencies)
        const filteredPromotions = allPromotions.filter(isPromotionValidForCatalog((catalogId as Catalog) || ''))

        const priceInfo = getPrices(
          item.product,
          storeId as KK_STORE_TYPE,
          locale as string,
          filteredPromotions,
          currencyOpt,
          '1'
        )

        return {
          quantity: item.quantityDesired,
          sku: item.product.sku,
          ...priceInfo,
        }
      }),
    [allPromotions, catalogId, currencies, locale, storeId, wishlist]
  )
}
