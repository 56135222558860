import type { FC } from 'react'
import s from '@components/home/HtmlSlider.module.scss'
import { CwsCarousel, CwsCarouselItem } from '@components/cws'
import Link from 'next/link'
import type { ImagePageBanner } from '@api/content'
import { CldImage } from 'next-cloudinary'
import { useMediaLess } from '@ui/hooks/useMediaLess'

const BANNER_HEIGHT = '460'

export interface TopBannerSliderProps {
  imageBanners: Omit<ImagePageBanner, 'title'>[]
}

export const TopBannerSlider: FC<TopBannerSliderProps> = ({ imageBanners }) => {
  const shouldAutoplay = imageBanners.length > 1
  const isMobile = useMediaLess(600)
  if (imageBanners.length === 1) {
    const [banner] = imageBanners
    return (
      <section className="mt-4">
        <Link key={banner.image} href={banner.clickUrl as string} passHref>
          <section style={{ width: '100%', aspectRatio: '1288 / 460', position: 'relative' }}>
            <CldImage
              alt={`image-banner-url-1`}
              layout="fill"
              namedTransformations={isMobile ? ['m-banner'] : ['banner']}
              src={banner.image}
            />
          </section>
        </Link>
      </section>
    )
  }

  return (
    <section>
      <CwsCarousel mode={'hero'} autoplay={shouldAutoplay}>
        {imageBanners.map(({ clickUrl, image }, idx) => {
          if (image.endsWith('html')) {
            return (
              <div key={image}>
                <iframe className={s.window} key={image} allowFullScreen={false} src={image} height={BANNER_HEIGHT} />
              </div>
            )
          }
          if (clickUrl)
            return (
              <CwsCarouselItem className="hover:opacity-90" key={image}>
                <Link key={image} href={clickUrl} passHref>
                  <section style={{ width: '100%', aspectRatio: '1288 / 460', position: 'relative' }}>
                    <CldImage
                      layout="fill"
                      src={image}
                      namedTransformations={isMobile ? ['m-banner'] : ['banner']}
                      alt={`image-banner-url-${idx}`}
                    />
                  </section>
                </Link>
              </CwsCarouselItem>
            )
          return (
            <CwsCarouselItem key={image}>
              <section style={{ width: '100%', aspectRatio: '1288 / 460', position: 'relative' }}>
                <CldImage
                  alt={`image-banner-url-${idx}`}
                  layout="fill"
                  src={image}
                  namedTransformations={isMobile ? ['m-banner'] : ['banner']}
                />
              </section>
            </CwsCarouselItem>
          )
        })}
      </CwsCarousel>
    </section>
  )
}
