import { useCallback, useEffect, useMemo, useState } from 'react'
import useSWRImmutable from 'swr/immutable'
import { useRouter } from 'next/router'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import { compose, map, propOr, sum } from 'ramda'
import { wishlistItemsAdder, wishlistItemsFetcher, wishlistItemsRemover } from '@api/wishlist'
import type { AddToWishlistItem, WishlistItem, WishlistState } from '@model/wishlist/WishlistItem'
import type { Catalog } from '@model/pricelist/Catalog'
import { KK_STORE } from '@api/apiConfig'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import { getCurrencyOptByLanguageId } from '@hooks/useCurrency'
import { isPromotionValidForCatalog } from '@utils/promotion'
import { getPrices } from '@utils/product/product-info'
import type { KK_STORE_TYPE } from '@pages/api/proxy'
import { useAppState } from '@context/state'
import { useInitialData } from '@context/initialDataContext'

type SortVariants = {
  [key in string]: (a: WishlistItem, b: WishlistItem) => number
}
const sortVariants: SortVariants = {
  'a-z': (a: WishlistItem, b: WishlistItem) => (a.product.name > b.product.name ? 1 : -1),
  'z-a': (a: WishlistItem, b: WishlistItem) => (b.product.name > a.product.name ? 1 : -1),
  'price-ascending': (a: WishlistItem, b: WishlistItem) => {
    return a.product.priceInfo.topPrice - b.product.priceInfo.topPrice
  },
  'price-descending': (a: WishlistItem, b: WishlistItem) => {
    return b.product.priceInfo.topPrice - a.product.priceInfo.topPrice
  },
}

interface WishlistHook {
  wishlist: WishlistItem[]
  cartError: unknown
  sortBy: string
  addToWishlist(item: AddToWishlistItem): Promise<boolean>
  removeWishlistItem(wishListItemId: number): Promise<void>
  clearList(): Promise<void>
  changeSortBy(value: string): void
  itemsCount: number
  wishListId: number
  totalPrice: number
}

const countWishlistItems = compose(sum, map<WishlistItem, number>(propOr(0, 'quantityDesired')))

export const useWishlist: () => WishlistHook = () => {
  const [count, setCount] = useState(0)
  const [wishList, setWishList] = useState<WishlistItem[]>([])
  const [sortBy, setSortBy] = useState<string>('a-z')

  const storeId = KK_STORE || isGloballyPricelist() ? 'store1' : 'store2'
  const handleRouteChange = () => {
    const event = new Event('cws-header-activate')

    dispatchEvent(event)
  }
  const {
    locale,
    query: { catalogId },
  } = useRouter()
  const {
    initialData: { allPromotions, currencies },
  } = useInitialData()

  const languageId = KK_LANG_IDS[locale as Locale]
  const currencyOpt = getCurrencyOptByLanguageId(languageId, currencies)
  const filteredPromotions = allPromotions.filter(isPromotionValidForCatalog((catalogId as Catalog) || ''))

  const {
    state: { customer },
  } = useAppState()
  const key = useMemo(() => `WISHLIST-${catalogId}${customer ? '-' + customer.ssoToken : ''}`, [catalogId, customer])

  const { data, error, mutate, isValidating } = useSWRImmutable<WishlistState>(
    key,
    wishlistItemsFetcher(languageId, catalogId as Catalog)
  )

  const removeWishlistItem = useCallback(
    async (wishListItemId: number) => {
      try {
        await wishlistItemsRemover(languageId, catalogId as Catalog, wishListItemId)()
        await mutate(() => undefined)
        if (wishList.length === 1) {
          handleRouteChange()
        }
      } catch (e) {
        console.error('an error occurred during deletion')
      }
    },
    [languageId, catalogId, wishList]
  )

  const clearList = useCallback(async () => {
    if (!data?.wishListItems) return
    await Promise.all(
      data?.wishListItems.map((item) => {
        return removeWishlistItem(item.id)
      })
    )
  }, [data, removeWishlistItem])

  const changeSortBy = (value: string) => {
    setSortBy(value)
  }
  const addToWishlist = useCallback(
    async (item: AddToWishlistItem) => {
      if (!data) return false
      try {
        await wishlistItemsAdder(languageId, catalogId as Catalog, { ...item, wishListId: data.id })()
        await mutate(() => undefined) // just to trigger a fetch todo: merge current data with the new item
        return true
      } catch (e) {
        console.error(`Cannot add to wishlist product ${item.product.sku} = `, (e as Error).message)
        return false
      }
    },
    [catalogId, data, languageId]
  )

  useEffect(() => {
    if (data && !isValidating) {
      const listItems = data?.wishListItems || []

      setCount(countWishlistItems(data.wishListItems))
      if (sortBy && sortVariants[sortBy]) {
        setWishList([...listItems].sort(sortVariants[sortBy]))
      } else {
        setWishList(listItems)
      }
    }
  }, [data, isValidating, sortBy])

  const priceListTotal = useMemo(() => {
    if (!data) return 0

    const prices = data.wishListItems.map((item) => {
      const { topPrice } = getPrices(
        item.product,
        storeId as KK_STORE_TYPE,
        locale as string,
        filteredPromotions,
        currencyOpt,
        '1'
      )
      return item.quantityDesired * topPrice
    })
    return sum(prices)
  }, [currencyOpt, data, filteredPromotions, locale, storeId])

  return {
    wishlist: wishList,
    wishListId: data?.id || 0,
    sortBy,
    changeSortBy,
    cartError: error,
    addToWishlist,
    clearList,
    removeWishlistItem,
    itemsCount: count,
    totalPrice: priceListTotal,
  }
}
