import { createContext, useContext } from 'react'

export interface AlertOptions {
  text: string
  hideDelay?: number
  error?: boolean | string
}

export interface AlertContextProps {
  showAlert: (options: AlertOptions) => any
  hideAlert: () => any
  alertOptions?: AlertOptions | null
}

export const AlertContext = createContext<AlertContextProps>({} as any)

export function useAlert() {
  return useContext(AlertContext)
}
