import s from '@components/common/Header/IntegratedHeader.module.scss';
import { CwsA, CwsHeaderNav, CwsHeaderNavItem } from '@components/cws'
import { usePreloadedInitialDataData } from '@context/PreloadedInitialDataContext';
import { keys } from 'ramda'
import React from 'react'

export const UpperNavigation: React.FC = () => {
  const items = usePreloadedInitialDataData().topNavigation
  const navItems = keys(items)

  return (
    <CwsHeaderNav className={s.headerNavPreloaded}>
      {navItems.map((key, index, array) => (
        <CwsHeaderNavItem
          divider={index === array.length - 1}
          current={items[key] === '/'}
          key={key}
          href={items[key]}
          label={key}
        >
          <CwsA target="_blank" hideArrow size="small" href={items[key]}>
            {key}
          </CwsA>
        </CwsHeaderNavItem>
      ))}
    </CwsHeaderNav>
  )
}
