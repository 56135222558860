import { Controller, useForm } from 'react-hook-form'
import { RadioButton } from '@ui/components'
import * as R from 'ramda'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useTranslate } from '@hooks/useTranslation'
import type { DeliveryMethod, DeliveryMethodWithQuote } from '@api/ecw/models/DeliveryMethod'
import { useCartState } from '@components/cart/State/state'
import type { KonakartCarrierCode, ShippingQuote } from '@model/order/ShippingQuote'
import { useCurrency } from '@hooks/useCurrency'
import { getFormattedPrice } from '@utils/price'
import type { CountryName } from '@model/locales'
import { COUNTRY_ISO2 } from '@model/locales'
import { CwsP } from '@components/cws'
import { useCartGaEvents } from '@hooks/useCartGaEvents'

interface Fields {
  deliveryType: 'express' | 'parcel' | 'home' | 'taxi'
}

type Predicate = {
  parcelMachine: boolean
  express: boolean
}

const getFirstCostSafely = R.pathOr(0, [0, 'cost'])
const getCostSafely = R.propOr(0, 'cost')

export const prototypeOptions = {
  'basket.method.expressps.delivery.txt': {
    parcelMachine: true,
    express: true,
    helperTextKey: 'w2.expressps.info',
  },
  'basket.courier.selection.machine.filter.label': {
    parcelMachine: true,
    express: false,
    helperTextKey: '',
  },
  'basket.method.taxi.delivery.txt': {
    parcelMachine: false,
    express: true,
    helperTextKey: 'basket.shipping.zip.not.possible.to.ship',
  },
  'basket.method.home.delivery.txt': {
    parcelMachine: false,
    express: false,
    helperTextKey: '',
  },
} as const

export type ProtoTypeVariant = keyof typeof prototypeOptions

export const defaultProtoType: Record<CountryName, ProtoTypeVariant> = {
  Estonia: 'basket.method.expressps.delivery.txt',
  Finland: 'basket.method.expressps.delivery.txt',
  Sweden: 'basket.courier.selection.machine.filter.label',
  Latvia: 'basket.courier.selection.machine.filter.label',
  Lithuania: 'basket.courier.selection.machine.filter.label',
}

interface DeliveryPrototypeProps {
  deliveryMethods: DeliveryMethod[]
  codesMap: Record<KonakartCarrierCode, DeliveryMethodWithQuote>
  countryName: CountryName
  fromReturn?: boolean
}

export const DeliveryPrototype: FC<DeliveryPrototypeProps> = ({
  deliveryMethods,
  countryName,
  codesMap,
  fromReturn = false,
}) => {
  const { translate } = useTranslate()
  const {
    dispatch,
    state: { deliveryMethodsByCountry, deliveryCountry, deliveryVariant },
  } = useCartState()
  const { control } = useForm<Fields>({
    defaultValues: {},
  })
  const { addShippingInfoGaEvent } = useCartGaEvents(fromReturn)
  const { currency } = useCurrency()

  useEffect(() => {
    switch (deliveryVariant) {
      case 'basket.method.home.delivery.txt':
      case 'basket.method.taxi.delivery.txt': {
        dispatch({ type: 'setSelectDeliveryPoint', payload: null })
      }
    }

    if (deliveryVariant === 'basket.method.taxi.delivery.txt') {
      dispatch({
        type: 'selectDeliveryMethodForCountry',
        payload: { country: deliveryCountry, method: codesMap['EKSPRESS'] },
      })
    }
  }, [deliveryVariant, dispatch, deliveryCountry, codesMap])

  useEffect(() => {
    // auto selecting the method
    if (!deliveryMethods || !deliveryMethods.length || deliveryMethodsByCountry[countryName]) return
    const predicates: Predicate = prototypeOptions[defaultProtoType[countryName]]
    const countryIso = COUNTRY_ISO2[countryName]
    const availableMethodsForKey = deliveryMethods
      .filter(({ country }) => country === countryIso)
      .filter(({ parcelMachine, express, country }) => {
        if (country === 'EE' || country === 'FI')
          return express === predicates.express && parcelMachine === predicates.parcelMachine
        else return parcelMachine === predicates.parcelMachine
      })

    if (availableMethodsForKey.length) {
      dispatch({
        type: 'selectDeliveryMethodForCountry',
        payload: { country: countryName, method: codesMap[availableMethodsForKey[0].konakartCarrierCode] },
      })
    } else
      deliveryMethodsByCountry[countryName] &&
        dispatch({
          type: 'selectDeliveryMethodForCountry',
          payload: { country: countryName, method: null },
        })
  }, [codesMap, deliveryMethods, dispatch, deliveryMethodsByCountry, countryName])

  const getQuotesSortedByPrice = R.compose(
    R.sortBy(R.prop('cost')),
    R.map(R.prop('quote')),
    R.map((code: KonakartCarrierCode) => codesMap[code]),
    R.map(R.prop('konakartCarrierCode'))
  )

  return (
    <form className="flex flex-col w-full">
      <fieldset className="flex flex-col gap-y-6 lg:gap-y-4 w-full">
        <Controller
          control={control}
          name="deliveryType"
          render={({ field: { value, onChange, ...field } }) => {
            return (
              <>
                {Object.keys(prototypeOptions).map((key) => {
                  const predicates = prototypeOptions[key as ProtoTypeVariant]
                  const availableMethodsForKey = deliveryMethods.filter(
                    ({ parcelMachine, express }) =>
                      express === predicates.express && parcelMachine === predicates.parcelMachine
                  )

                  if (availableMethodsForKey.length < 1) return null

                  const allQuotes: ShippingQuote[] = getQuotesSortedByPrice(availableMethodsForKey)

                  const cheapestPrice = getFirstCostSafely(allQuotes)
                  let highestPrice = 0

                  if (allQuotes.length > 1) {
                    highestPrice = getCostSafely(R.last(allQuotes) as ShippingQuote)
                  }

                  return (
                    <React.Fragment key={key}>
                      <RadioButton
                        key={key}
                        label={translate(key)}
                        checked={deliveryVariant === key}
                        value={value}
                        onClick={() => {
                          dispatch({ type: 'setDeliveryVariant', payload: key as ProtoTypeVariant })
                          dispatch({
                            type: 'selectDeliveryMethodForCountry',
                            payload: {
                              country: countryName,
                              method: codesMap[availableMethodsForKey[0].konakartCarrierCode],
                            },
                          })
                          if (fromReturn) {
                            dispatch({
                              type: 'setHomeDeliveryData',
                              payload: { streetAddress: '', city: '', zip: '', allFilled: false },
                            })
                            dispatch({ type: 'selectPickUpPointId', payload: '' })
                          }
                          if (!fromReturn) {
                            addShippingInfoGaEvent(key)
                          }
                        }}
                        labelClassName="w-full"
                        {...field}
                      >
                        {!fromReturn && (
                          <div className="flex flex-row items-center justify-between">
                            <div>{translate(key)}</div>
                            <div>
                              <span>
                                {getFirstCostSafely(allQuotes) === 0
                                  ? translate('basket.delivery.free')
                                  : getFormattedPrice(cheapestPrice, currency)}
                              </span>
                              {Boolean(highestPrice) && <span> - {getFormattedPrice(highestPrice, currency)}</span>}
                            </div>
                          </div>
                        )}
                      </RadioButton>
                      {predicates.helperTextKey && (
                        <CwsP className="cws-ml-28-lg">{translate(predicates.helperTextKey)}</CwsP>
                      )}
                    </React.Fragment>
                  )
                })}
              </>
            )
          }}
        />
      </fieldset>
    </form>
  )
}
