import { BASE_API } from '@api/baseApi';
import { getAllTranslation } from '@api/messages';
import { expireLongerTimeInSeconds, getData, setData } from '@api/redis'
import type { MessageLocale } from '@model/locales'

export type MessageKeyValue = Record<string, string>

const serverSideTranslationsKeys = [
  'w2.copyright.message',
  'footer.privacy.policy',
  'w2.terms.of.service',
  'subscription.benefits.title',
  'subscription.benefits.title.particle',
  'subscription.benefits.point.one',
  'subscription.benefits.point.two',
  'subscription.benefits.point.three',
  'subscription.benefits.button',
  'subscription.benefits.more',
  'subscription.title.pricelist',
  'subscription.title',
  'subscription.title.particle.pricelsit',
  'subscription.title.particle',
  'subscription.description',
  'subscription.email.label',
  'subscription.button',
];

export const getPreloadedSubsetTranslations = async (
  locale: MessageLocale = 'et_EE',
): Promise<MessageKeyValue> => {
  const cKey = `getTranslationsSubset-${locale}`;
  const cached = await getData<MessageKeyValue>(cKey);

  if (cached) {
    return cached;
  }

  const regularTranslations = await BASE_API.getAllTranslation(locale);

  // Extract only the required subset of keys
  const subset: MessageKeyValue = {};
  serverSideTranslationsKeys.forEach((key) => {
    subset[key] = regularTranslations[key];
  });

  // Cache the subset for future use
  await setData(cKey, subset, expireLongerTimeInSeconds);
  return subset;
};
